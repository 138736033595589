import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { getApiCall } from "../../apiServices";
import { CCol, CRow, CPagination, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CCard, CCardBody} from "@coreui/react";
import { Table, Button, Form, Checkbox } from "semantic-ui-react";
import Modal from 'react-bootstrap/Modal';
import { postAPI } from "./../../apiServices";
const defaultState = {
  entries: [],
  totalEntries: 0,
  page: 0,
  recPerPage: 50,
  searchKeyword: "",
  loading: true,
  slugType: "category",
  confirmDialog: null,
  selectedItem: [],
};
const localPermits = JSON.parse(localStorage.getItem("module"));
const RoleManagement = () => {
  window.scrollTo(0, 0);
  const [state, setState] = useState(defaultState);
  const [roleId, setRoleId] = useState(null);
  const [rebind, setrebind] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visibleadd, setVisibleadd] = useState(false);
  const [userRoleList, setuserRoleList] = useState([]);
  const [userModule, setuserModule] = useState(defaultState.selectedItem);
  const [userRole, setuserRole] = useState([]);
  const [userRoleName, setuserRoleName] = useState(null);
  const [userModuleList, setuserModuleList] = useState([]);
  const [inabledRow, setinabledRow] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId === 40)
  );
  const [roleAssign, setRoleAssign] = useState(
    permissions?.childItems.find((o) => o.moduleId === 41)
  );

  const getModuleData = async () => {
    const { status, payload } = await getApiCall("/modules/list");
    if (status) {
      setuserModuleList(payload.items);
    }
  };
  const getUserListRole = async () => {
    const { status, payload } = await getApiCall("/roles/list");
    if (status) {
      const data = payload?.filter((item) => item.roleId !== 1);

      setuserRoleList(data);
    }
  };

  useEffect(() => {
    getUserListRole();
    getModuleData();
  }, []);

  const handleDeleteConfirm = async (data) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    setState((prevState) => ({
      ...prevState,
      loading: false,
    }));
  };
  const deleteUserRole = (role) => {
    alert(role?.roleId);
  };

  const editUserRole = (role) => {
    let newArray = role?.modules;
    defaultState.selectedItem.push(...newArray);
    setuserRole(role?.modules);
    setuserRoleName(role?.roleName);
    setRoleId(role?.roleId);
    setVisible(true);
  };

  const addUserRole = async () => {
    if (userRoleName === "" || userRoleName === null) {
      return;
    }
    setisLoading(true);
    const roleData = {
      roleId: roleId ? roleId : "",
      roleName: userRoleName,
      roleModules: userModule,
    };
    const { status, message } = await postAPI("/roles/manage", roleData);
    if (status) {
      getUserListRole();
    }
    defaultState.selectedItem = [];
    setVisible(false);
    setVisibleadd(false);
    toast.info(message);
    setisLoading(false);
  };

  const handleTrashChange = (e, moduleID) => {
    let value = e.target.checked;
    let objIndex = defaultState.selectedItem.findIndex(
      (obj) => obj.moduleId === moduleID
    );
    if (objIndex !== -1) {
      defaultState.selectedItem[objIndex].isTrash = value ? 1 : 0;
      if(!value){
        if(defaultState.selectedItem[objIndex].isView === 0 &&
          defaultState.selectedItem[objIndex].isEdit === 0 &&
          defaultState.selectedItem[objIndex].isAdd === 0 &&
          defaultState.selectedItem[objIndex].isTrash === 0 &&
          defaultState.selectedItem[objIndex].isDownload === 0){
            defaultState.selectedItem.splice(objIndex, 1);
          }
      }
    }
    setrebind(!rebind);
  };
  const handleEditChange = (e, moduleID) => {
    let value = e.target.checked;
    let objIndex = defaultState.selectedItem.findIndex(
      (obj) => obj.moduleId === moduleID
    );
    if (objIndex !== -1) {
      defaultState.selectedItem[objIndex].isEdit = value ? 1 : 0;
      if(!value){
        if(defaultState.selectedItem[objIndex].isView === 0 &&
          defaultState.selectedItem[objIndex].isEdit === 0 &&
          defaultState.selectedItem[objIndex].isAdd === 0 &&
          defaultState.selectedItem[objIndex].isTrash === 0 &&
          defaultState.selectedItem[objIndex].isDownload === 0){
            defaultState.selectedItem.splice(objIndex, 1);
          }
      }
    }
    setrebind(!rebind);
  };

  const handleAddChange = (e, moduleID) => {
    let value = e.target.checked;
    let objIndex = defaultState.selectedItem.findIndex(
      (obj) => obj.moduleId === moduleID
    );
    if (objIndex !== -1) {
      defaultState.selectedItem[objIndex].isAdd = value ? 1 : 0;
      if(!value){
        if(defaultState.selectedItem[objIndex].isView === 0 &&
          defaultState.selectedItem[objIndex].isEdit === 0 &&
          defaultState.selectedItem[objIndex].isAdd === 0 &&
          defaultState.selectedItem[objIndex].isTrash === 0 &&
          defaultState.selectedItem[objIndex].isDownload === 0){
            defaultState.selectedItem.splice(objIndex, 1);
          }
      }
    }
    setrebind(!rebind);
  };

  const handleDownloadChange = (e, moduleID) => {
    let value = e.target.checked;
    let objIndex = defaultState.selectedItem.findIndex(
      (obj) => obj.moduleId === moduleID
    );
    if (objIndex !== -1) {
      defaultState.selectedItem[objIndex].isDownload = value ? 1 : 0;
      if(!value){
        if(defaultState.selectedItem[objIndex].isView === 0 &&
          defaultState.selectedItem[objIndex].isEdit === 0 &&
          defaultState.selectedItem[objIndex].isAdd === 0 &&
          defaultState.selectedItem[objIndex].isTrash === 0 &&
          defaultState.selectedItem[objIndex].isDownload === 0){
            defaultState.selectedItem.splice(objIndex, 1);
          }
      }
    }
    setrebind(!rebind);
  };

  const handleViewChange = (e, moduleID) => {
    let value = e.target.checked;
    let objIndex = defaultState.selectedItem.findIndex(
      (obj) => obj.moduleId === moduleID
    );
    if (objIndex !== -1) {
      defaultState.selectedItem[objIndex].isView = value ? 1 : 0;
      if(!value){
        defaultState.selectedItem.splice(objIndex, 1);
      }
      // handleParentModule(moduleID)
    }
    setrebind(!rebind);
  };

  const checkItem = (e, { value, name }) => {
    let objIndex = defaultState.selectedItem.findIndex(
      (obj) => obj.moduleId === value
    );
    if (objIndex === -1) {
      defaultState.selectedItem.push({
        moduleId: value,
        isAdd: 0,
        isEdit: 0,
        isView: 1,
        isTrash: 0,
        isDownload: 0,
      });

      userModuleList?.map((pModule) => {
        if(pModule.moduleId === value){
          pModule?.childItems?.map((cModule) => {
            defaultState.selectedItem.push({
              moduleId: cModule?.moduleId,
              isAdd: 0,
              isEdit: 0,
              isView: 1,
              isTrash: 0,
              isDownload: 0,
            });
          })
        }
      })
      setinabledRow(value);
    } else {
      userModuleList?.map((pModule) => {
        if(pModule.moduleId === value){
          pModule?.childItems?.map((cModule) => {
            let childObjIndex = defaultState.selectedItem.findIndex(
              (obj) => obj.moduleId === cModule?.moduleId
            );
            defaultState.selectedItem.splice(childObjIndex, 1);
          })
        }
      })
      defaultState.selectedItem.splice(objIndex, 1);
    }
    setrebind(!rebind);
  };

  const handleParentModule = (moduleID) => {
    let objIndex = defaultState.selectedItem.findIndex(
      (obj) => obj.moduleId === moduleID
    );
    if (objIndex !== -1) {
      let isView = defaultState.selectedItem[objIndex].isView;
      let isAdd = defaultState.selectedItem[objIndex].isAdd;
      let isEdit = defaultState.selectedItem[objIndex].isEdit;
      let isTrash = defaultState.selectedItem[objIndex].isTrash;
      let isDownload = defaultState.selectedItem[objIndex].isDownload;
      if(isView === 0 && isAdd === 0 && isEdit === 0 && isTrash === 0 && isDownload === 0){
        defaultState.selectedItem.splice(objIndex, 1);
      }
    }
  }

  return (
    <React.Fragment>
      <CRow>
        <CCol lg={12}>
          <CRow>
            <CCol lg={9}>
              <div style={{ width: 200, marginBottom: 10 }}>
                <Form>
                  <Form.Field></Form.Field>
                </Form>
              </div>
            </CCol>
            <CCol lg={3}>
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="d-flex w-50"></div>
                <div className="d-flex " style={{ marginBottom: 10 }}>
                  <CButton
                    disabled={roleAssign.isAdd === 0 ? true : false}
                    color="primary"
                    className="ms-1 me-1"
                    onClick={() => {
                      setVisibleadd(!visibleadd);
                      setuserRoleName("");
                    }}
                  >
                    Add Role
                  </CButton>
                </div>
              </div>
            </CCol>
          </CRow>
          {userRoleList?.length === 0 ? (
            <div className="d-flex flex-column align-items-center">
              <p>No Role Found</p>
            </div>
          ) : (
            <>
              <CCard>
                <CCardBody>
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ border: 0 }}>
                          S.No
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Role Name
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Module
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          <div className="d-flex align-items-center justify-content-center">
                            Action
                          </div>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {userRoleList?.map((roles, index) => (
                        <Table.Row key={index}>
                          <Table.Cell>{index + 1 + "."}</Table.Cell>
                          <Table.Cell>{roles?.roleName}</Table.Cell>
                          <Table.Cell>
                            <>
                              {roles?.modules
                                ? roles?.modules[0]?.moduleName
                                : ""}
                            </>
                          </Table.Cell>
                          <Table.Cell>
                            <div style={{ textAlign: "center" }}>
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={{ cursor: "pointer" }}
                                color="primary"
                                className={
                                  roleAssign.isTrash === 0
                                    ? "me-4 disabled"
                                    : "me-4 text-danger"
                                }
                                // className="me-4 text-danger disabled"
                                onClick={() => deleteUserRole(roles)}
                              />
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                style={{ cursor: "pointer" }}
                                color="primary"
                                className={
                                  roleAssign.isEdit === 0
                                    ? "me-4  disabled"
                                    : "me-4 text-primary"
                                }
                                // className="me-2 text-primary disabled"
                                onClick={() => editUserRole(roles)}
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </CCardBody>
              </CCard>
              {userRoleList?.length && (
                <div className="d-flex align-items-center justify-content-between">
                  <p>{`Showing ${state.recPerPage * state.page + 1} to ${
                    state.recPerPage * state.page + state.entries.length
                  } of ${state.totalEntries} record(s)`}</p>
                  <CPagination
                    activePage={state.page + 1}
                    pages={Math.ceil(state.totalEntries / state.recPerPage)}
                    onActivePageChange={(page) => {}}
                  />
                </div>
              )}
            </>
          )}
        </CCol>
      </CRow>
      <CModal
        alignment="center"
        show={!!state.confirmDialog}
        // onClosed={() => {
        //   setState((prevState) => ({
        //     ...prevState,
        //     confirmDialog: null,
        //   }));
        // }}
        backdrop="static" keyboard={false} size="lg"
      >
        <CModalHeader>
          <CModalTitle>{`${state.confirmDialog?.action}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>{`Are you sure you want to continue?`}</div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }));
            }}
          >
            Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              handleDeleteConfirm(state?.confirmDialog?.entry);
            }}
          >
            Delete
          </CButton>
        </CModalFooter>
      </CModal>

      <Modal show={visible} backdrop="static" keyboard={false} size="lg">
        <Modal.Body>
          <CCard>
            <CCardBody
              style={{
                height: "500px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Form onSubmit={() => addUserRole()} loading={isLoading}>
                <Form.Field>
                  <Form.Input
                    required
                    label="Role Name"
                    name="roleName"
                    value={userRoleName}
                    onChange={(e) => setuserRoleName(e.currentTarget.value)}
                  />
                </Form.Field>
                <React.Fragment>
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Module
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Read
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Create
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Update
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Delete
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Download
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {userModuleList?.map((module, index) => (
                        <React.Fragment>
                          <Table.Row key={index}>
                            <Table.Cell>
                              <Checkbox
                                name="moduleId"
                                value={module?.moduleId}
                                label={module?.moduleName}
                                checked={defaultState.selectedItem.find(
                                  (item) => item.moduleId === module?.moduleId
                                )}
                                onChange={checkItem}
                              />{" "}
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isView}
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isView &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                name="isView"
                                onChange={(e) =>
                                  handleViewChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isAdd}
                                name="isAdd"
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isAdd &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                onChange={(e) =>
                                  handleAddChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isEdit}
                                name="isEdit"
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isEdit &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                onChange={(e) =>
                                  handleEditChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isTrash}
                                name="isTrash"
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isTrash &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                onChange={(e) =>
                                  handleTrashChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isDownload}
                                name="isDownload"
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isDownload &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                onChange={(e) =>
                                  handleDownloadChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                          {module.childItems?.length > 0 ? (
                            <React.Fragment>
                              {module?.childItems.map((childM) => (
                                <Table.Row key={Math.random()}>
                                  <Table.Cell>
                                    <Checkbox
                                      style={{ marginLeft: "20px" }}
                                      name="moduleId"
                                      value={childM?.moduleId}
                                      checked={defaultState.selectedItem.find(
                                        (item) =>
                                          item.moduleId === childM?.moduleId
                                      )}
                                      onChange={checkItem}
                                      label={childM?.moduleName}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isView}
                                      name="isView"
                                      defaultChecked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isView &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleViewChange(e, childM?.moduleId)
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isAdd}
                                      name="isAdd"
                                      defaultChecked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isAdd &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleAddChange(e, childM?.moduleId)
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isEdit}
                                      name="isEdit"
                                      defaultChecked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isEdit &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleEditChange(e, childM?.moduleId)
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isTrash}
                                      name="isTrash"
                                      defaultChecked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isTrash &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleTrashChange(e, childM?.moduleId)
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isDownload}
                                      name="isDownload"
                                      checked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isDownload &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleDownloadChange(
                                          e,
                                          childM?.moduleId
                                        )
                                      }
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ))}
                    </Table.Body>
                  </Table>
                </React.Fragment>
              </Form>
            </CCardBody>
          </CCard>
          <div style={{ float: "right" }}>
            <Button
              gray
              type="button"
              onClick={() => {
                setVisible(false);
                defaultState.selectedItem = [];
              }}
            >
              Cancel
            </Button>
          </div>
          <div style={{ float: "right" }}>
            <Button primary type="button" onClick={() => addUserRole()}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* add role */}
      <Modal show={visibleadd} backdrop="static" keyboard={false} size="lg">
        <Modal.Body>
          <CCard>
            <CCardBody
              style={{
                height: "500px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Form onSubmit={() => addUserRole()}>
                <Form.Field>
                  <Form.Input
                    required
                    label="Role Name"
                    name="roleName"
                    value={userRoleName}
                    onChange={(e) => setuserRoleName(e.currentTarget.value)}
                  />
                </Form.Field>
                <React.Fragment>
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Module
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Read
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Create
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Update
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Delete
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Download
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {userModuleList?.map((module, index) => (
                        <React.Fragment>
                          <Table.Row key={index}>
                            <Table.Cell>
                              <Checkbox
                                name="moduleId"
                                value={module?.moduleId}
                                label={module?.moduleName}
                                checked={defaultState.selectedItem.find(
                                  (item) => item.moduleId === module?.moduleId
                                )}
                                onChange={checkItem}
                              />{" "}
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isView}
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isView &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                name="isView"
                                className={"isView"+module?.moduleId}
                                onChange={(e) =>
                                  handleViewChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isAdd}
                                name="isAdd"
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isAdd &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                onChange={(e) =>
                                  handleAddChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isEdit}
                                name="isEdit"
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isEdit &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                onChange={(e) =>
                                  handleEditChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isTrash}
                                name="isTrash"
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isTrash &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                onChange={(e) =>
                                  handleTrashChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                            <Table.Cell>
                              <input
                                style={{
                                  transform: "scale(1.5)",
                                  cursor: "pointer",
                                }}
                                type="checkbox"
                                value={userRole?.isDownload}
                                name="isDownload"
                                checked={
                                  defaultState.selectedItem.filter(
                                    (item) =>
                                      item.isDownload &&
                                      item.moduleId === module?.moduleId
                                  )?.length > 0
                                }
                                onChange={(e) =>
                                  handleDownloadChange(e, module?.moduleId)
                                }
                              />
                            </Table.Cell>
                          </Table.Row>
                          {module.childItems?.length > 0 ? (
                            <React.Fragment>
                              {module?.childItems.map((childM) => (
                                <Table.Row key={Math.random()}>
                                  <Table.Cell>
                                    <Checkbox
                                      style={{ marginLeft: "20px" }}
                                      name="moduleId"
                                      value={childM?.moduleId}
                                      checked={defaultState.selectedItem.find(
                                        (item) =>
                                          item.moduleId === childM?.moduleId
                                      )}
                                      onChange={checkItem}
                                      label={childM?.moduleName}
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isView}
                                      name="isView"
                                      className={"isView"+module?.moduleId}
                                      checked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isView &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleViewChange(e, childM?.moduleId)
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isAdd}
                                      name="isAdd"
                                      checked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isAdd &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleAddChange(e, childM?.moduleId)
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isEdit}
                                      name="isEdit"
                                      checked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isEdit &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleEditChange(e, childM?.moduleId)
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isTrash}
                                      name="isTrash"
                                      checked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isTrash &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleTrashChange(e, childM?.moduleId)
                                      }
                                    />
                                  </Table.Cell>
                                  <Table.Cell>
                                    <input
                                      style={{
                                        transform: "scale(1.5)",
                                        cursor: "pointer",
                                      }}
                                      type="checkbox"
                                      value={userRole?.isDownload}
                                      name="isDownload"
                                      checked={
                                        defaultState.selectedItem.filter(
                                          (item) =>
                                            item.isDownload &&
                                            item.moduleId === childM?.moduleId
                                        )?.length > 0
                                      }
                                      onChange={(e) =>
                                        handleDownloadChange(
                                          e,
                                          childM?.moduleId
                                        )
                                      }
                                    />
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </React.Fragment>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ))}
                    </Table.Body>
                  </Table>
                </React.Fragment>
              </Form>
            </CCardBody>
          </CCard>
          <div style={{ float: "right" }}>
            <Button gray type="button" onClick={() => { defaultState.selectedItem = []
              setVisibleadd(false)
              }}>
              Cancel
            </Button>
          </div>
          <div style={{ float: "right" }}>
            <Button primary type="button" onClick={() => addUserRole()}>
              Save
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default RoleManagement;
