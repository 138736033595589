import React, { useEffect, useState } from "react";
import { Card } from "semantic-ui-react";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { Tab } from 'semantic-ui-react'
import ProductReportDownload from "./components/productDownload";
import SalesReport from "./components/saleReport";
import PurchaseReport from "./components/purchaseReport";
// import BulkInvoiceReport from "./components/bulkInvoiceReport";
import { useNavigate, useLocation } from "react-router-dom";
import { cibHuawei } from "@coreui/icons";
import { get, getApiCall } from "../../apiServices";
import CancelledOrderReport from "./components/cancelledOrder";
import { getDateBeforeNDays } from "../../constants/comonFunction";
import {REACT_APP_ALL_REPORT_MODULE_ID} from "../../constants/constVariable";

const roleName = JSON.parse(localStorage.getItem("roleName"));
const localPermits = JSON.parse(localStorage.getItem("module"));
const tabs = [{key: "productUpload", title:"Product Upload", subTitle:"Product uploaded by a seller", icon:"book icon", module:"ProductReportDownload"}]
function AllReport() {
    const search = useLocation().search;
    let type = new URLSearchParams(search).get("type");
    const navigate = useNavigate();
    const [activeIndexNo, setActiveIndexNo] = useState();
    const [panesList, setPanesList] = useState();
    const moduleId = REACT_APP_ALL_REPORT_MODULE_ID;
    window.scrollTo(0, 0);
    useEffect(() => {
        const getSubModule = async () => {
            let tabs = [];
            let panes2 = [];
            let res = await getApiCall(`/modules/sub-modules-list?moduleId=${moduleId}`);

            let mindate = '04/01/2023'
            if(roleName === 'Logistics Manager' || roleName === 'Customer Support' || roleName === 'Procurement Manager'){
              let dateBefore90Days = getDateBeforeNDays(3, 'months')
              mindate = `${dateBefore90Days.getMonth() + 1}/${dateBefore90Days.getDate()}/${dateBefore90Days.getFullYear()}`;
            }
            if(res.status === 1){
                let payload = res?.payload;
                payload?.forEach((childEle, i) => {
                    if(childEle?.isDownload === 1){
                        tabs.push(childEle?.modulePath)
                        panes2.push(
                            {
                                menuItem: { key: childEle?.moduleName, value:i, content: <>
                                <span onClick={() => handleTab(childEle?.modulePath, i)} style={{fontFamily: "sans-serif"}}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={{width:"50px"}}><span className="verticlenavIcon"><i className={childEle?.moduleIcon}></i></span></td>
                                                <td><span><span className="title" style={{fontSize:"16px"}}>{childEle?.moduleName}</span><p className="subtitle" style={{fontSize:"12px"}}>{childEle?.subTitle}</p></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                                </>},
                                render: () => <Tab.Pane>
                                        {childEle?.moduleName === 'Product Report' ? (<ProductReportDownload minDate={mindate} />) : (<></>)}
                                        {childEle?.moduleName === 'Sales Report' ? (<SalesReport minDate={mindate} />) : (<></>)}
                                        {childEle?.moduleName === 'Purchase Report' ? (<PurchaseReport minDate={mindate} />) : (<></>)}
                                        {childEle?.moduleName === 'Cancelled Orders Report' ? (<CancelledOrderReport minDate={mindate} />) : (<></>)}
                                        {/* {childEle?.moduleName === 'Download Invoice in bulk' ? (<BulkInvoiceReport minDate={mindate} />) : (<></>)} */}
                                    </Tab.Pane>
                            }

                        )
                    }
                })
            }
            let pathname = window.location.hash;
            const index = tabs.indexOf(pathname.substring(1))
            setPanesList(panes2)
            if(index > -1){
                await setActiveIndexNo(index)
            }else{
                setActiveIndexNo(0)
            }
            }
            getSubModule()
    }, [])



    const handleTab = (url, i) => {
        if(i !== undefined){
            navigate(url)
            setActiveIndexNo(i)
        }
    }

  return (
    <div className="container">
       <Card fluid>
       <Tab
            menu={{ fluid: true, vertical: true, borderless: true }}
            menuPosition='left'
            panes={panesList}
            grid={{paneWidth: 11, tabWidth: 5}}
            activeIndex={activeIndexNo}
            style={{fontFamily: "sans-serif"}}
        />
      </Card>
    </div>
  );
}

export default AllReport;
