import React, { useEffect, useState, useRef } from "react";
import { CCol, CRow, CModal, CModalHeader, CModalBody, CModalFooter, CCard, CCardBody} from "@coreui/react";

  import Swal from 'sweetalert2'
  import Select from 'react-select'
  import { Button, Table, Input, Form } from "semantic-ui-react";
  import { postAPI, getApiCall } from "../../../apiServices"

const PaymentSetting = () => {
    const selectInputRef = useRef();
    const formRef = useRef();
    const selectInputPaymentTypeRef = useRef();
    const refExclusionFor = useRef("state");
    const refIncludedPincode = useRef()
  // Modal variables
    const [excludedState, setExcluddState] = useState();
    const [showAddModal, setShowAddModal] = useState();
    const [showAddPincodeModal, setShowAddPincodeModal] = useState();
    const [showViewPincodeModal, setShowViewPincodeModal] = useState();
    const [showDeletePincodeModal, setShowDeletePincodeModal] = useState();
    const [showDeleteExceptionModal, setShowDeleteExceptionModal] = useState();

    const [states , setStates] = useState();
    const [selectedStates, setSelectedStates] = useState();
    const [stateCode, setStateCode] = useState();
    const [paymentMethods, setPaymentMethods] = useState();
    const [selectedPaymentMethods, setSelectedPaymentMethods] = useState();
    const [exclusionFor, setExclusionFor] = useState('state');
    const [selectedPincodes, setSelectedPincode] = useState();
    const [invalidPincodes, setInvalidPincodes] = useState();
    const [exceptionValue, setExceptionValue] = useState();
// Error variables
    const [selectedPaymentError, setSelectedPaymentError] = useState();
    const [exclusionForError, setExclusionForError] = useState();
    const [selectedStateError, setSelectedStateError] = useState();
    const [selectedPincodesError, setSelectedPincodesError] = useState();

    window.scrollTo(0, 0);

    useEffect(() => {
        getStates();
        getPaymentMethods();
    },[])

    useEffect(() => {
      getDetails();
    }, []);

    const getDetails = async () => {
      let detail_res = await getApiCall('/settings/payment-method/get-restricted-state');
      if(detail_res.status === 1){
        setExcluddState(detail_res.payload);
      }
    }

    const getStates = async () => {
        let state_res = await getApiCall('/settings/get-all-states')
        if(state_res.status === 1){

            let state = [];
            state_res.payload.map((row) => {
                state.push({ value: row.abbreviation, label: row.name },)
            })
            setStates(state)
        }
    }
    const getPaymentMethods = async () => {
        let pay_method_res = await getApiCall('/settings/get-payment-methods')
        if(pay_method_res.status === 1){
            setPaymentMethods(pay_method_res.payload)
        }
    }

    const changeExclusionFor = async (e) => {
      setExclusionFor(e.target.value)
    }

    const saveExclusion = async () => {
      setSelectedPaymentError();
      setExclusionForError();
      setSelectedStateError();
      setSelectedPincodesError();
      setInvalidPincodes();
      if(!selectedPaymentMethods){
        return setSelectedPaymentError('Required')
      }
      if(!exclusionFor){
        return setExclusionForError('Required')
      }
      if(exclusionFor === 'state' && !selectedStates || selectedStates?.length === 0){
        return setSelectedStateError("Required");
      }
      if(exclusionFor === 'pincode' && !selectedPincodes){
        return setSelectedPincodesError("Required");
      }
      let exceptionValue = [];
      if(exclusionFor === 'state'){
        selectedStates.map((state) => {
          exceptionValue.push({value:state.label, stateCode:state.value})
        })
      }
      else{
        let selectedPincodesArray = selectedPincodes.replace(',','\n').split('\n');
        let invalidPin = []
        selectedPincodesArray.forEach(pincode => {
          let pinTrim = pincode.trim().replace(/\D/g,'');
          if(pinTrim.length === 6){
            exceptionValue.push({value:pinTrim})
          }
          else{
            invalidPin.push(pincode);
          }
        });
        if(invalidPin.length > 1){
          setInvalidPincodes(JSON.stringify(invalidPin));
          return
        }
      }

      let data = {
        paymentMethod: selectedPaymentMethods.value,
        exclusionType: exclusionFor,
        exceptionValue: exceptionValue
      }
      let res = await postAPI('/settings/payment-method/save-restricted-state',data)
      if(res.status === 1){
        Swal.fire({
          title: 'Loading...',
          text: 'Please wait loading....',
          showConfirmButton: false,
          timer: 1500
        })
        resetState()
        setShowAddModal(false)
        getDetails()
      }
    }

    const viewPincodes = async (pincodes) => {
      let pincode = "";
      pincodes.forEach((pin) => {
        pincode = pincode+pin+", "
      })
      setSelectedPincode(pincode)
      setShowViewPincodeModal(true)
    }

// Add Include pincodes open modal
    const includePinCode = async (paymentMethod, stateCode) => {
      setSelectedPaymentMethods(paymentMethod);
      setStateCode(stateCode)
      setSelectedPincode();
      setShowAddPincodeModal(true)
    }
// save included pincodes
    const savePincodeInclusion = async() => {
      let selectedPincodesArray = selectedPincodes?.replace(',','\n').split('\n');
      let invalidPin = []
      let exceptionValue = []
      selectedPincodesArray?.forEach(pincode => {
        let pinTrim = pincode.trim().replace(/\D/g,'');
        if(pinTrim.length === 6){
          exceptionValue.push(pinTrim)
        }
        else{
          invalidPin.push(pincode);
        }
      });
      if(exceptionValue.length === 0){
        return setSelectedPincodesError("Required")
      }
      if(invalidPin.length > 1){
        setInvalidPincodes(JSON.stringify(invalidPin));
        return
      }
      let data = {
        paymentMethod: selectedPaymentMethods,
        stateCode: stateCode,
        pincodes: exceptionValue
      }
      let res = await postAPI('/settings/payment-method/save-inclusive-pincode', data);
      if(res.status === 1){
        let test = document.getElementById("Includedpincodes").value = ""
        setShowAddPincodeModal(false)
        Swal.fire({
          title: 'Successfull',
          text: res.message,
          showConfirmButton: false,
          timer: 1500
        })
        getDetails()
      }
    }

// Clear pincodes alert modal
    const clearPincodeInclusionModal = (paymentMethod, stateCode, pincodes) => {
      setSelectedPaymentMethods(paymentMethod);
      setStateCode(stateCode)
      let pincode = "";
      pincodes.forEach((pin) => {
        pincode = pincode+pin+", "
      })
      setSelectedPincode(pincode)
      setShowDeletePincodeModal(true)
    }
// Clear pin codes
    const clearPincodeInclusion = async () => {
      let data = {
        paymentMethod: selectedPaymentMethods,
        stateCode: stateCode,
      }
      let res = await postAPI('/settings/payment-method/delete-inclusive-pincode', data);
      if(res.status === 1){
        setShowDeletePincodeModal(false)
        Swal.fire({
          title: 'Successfull',
          text: res.message,
          showConfirmButton: false,
          timer: 1500
        })
        getDetails()
      }
    }

// Delete Exclusion alert Modal
    const deleteExceptionModal = (paymentMethod, exceptionFor, exceptionValue) => {
      setSelectedPaymentMethods(paymentMethod);
      setExclusionFor(exceptionFor)
      setExceptionValue(exceptionValue)
      setShowDeleteExceptionModal(true)
    }
// Delete Exlusion
    const deleteException = async() => {
      let exceptionValueArray = [];
      exceptionValueArray.push(exceptionValue)
      let data = {
        paymentMethod: selectedPaymentMethods.toLowerCase(),
        exclusionType: exclusionFor,
        exceptionValue: exceptionValueArray
      }
      let res = await postAPI('/settings/payment-method/delete-restricted-state', data);
      if(res.status === 1){
        setShowDeleteExceptionModal(false)
        Swal.fire({
          title: 'Successfull',
          text: res.message,
          showConfirmButton: false,
          timer: 1500
        })
        getDetails()
      }
    }

    const openAddExclusion = () => {
      setExclusionFor("state");
      selectInputRef?.current?.clearValue();
      selectInputPaymentTypeRef.current?.clearValue();
      setSelectedPincode();
      setShowAddModal(true)
    }

    const resetState = (e) => {
      setExclusionFor("state");
      selectInputRef?.current?.clearValue();
      selectInputPaymentTypeRef.current?.clearValue();
      setSelectedPincode();
      setShowAddModal(false)
    }

    const resetIncludedPincode = async (e) => {
      let test = document.getElementById("Includedpincodes").value = ""
      await setSelectedPincode();
      setShowAddPincodeModal(false)
    }

    return (
        <React.Fragment>
        <CRow>
            <CCol lg={9}>

            </CCol>
            <CCol lg={3}>
                <div className="d-flex align-items-center justify-content-between w-100">
                <div className="d-flex w-50"></div>
                <div className="d-flex " style={{ marginBottom: 10 }}>
                    <Button
                    color="primary"
                    style={{ width: "200px" }}
                    // onClick={() => {
                    //     setShowAddModal(true)
                    // }}
                    onClick={()=>openAddExclusion()}
                    >
                    Add Exception
                    </Button>

                </div>
                </div>
            </CCol>
            </CRow>
      <CRow>
        <CCol lg={12}>
            {excludedState ? (<React.Fragment>

              <CCard>
                <CCardBody>
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Payment Method
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Type
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Excluded
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Included Pincode
                        </Table.HeaderCell>


                        <Table.HeaderCell style={{ border: 0 }}>
                          Action
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {excludedState?.map((row) => {
                        return (
                          <>
                          <Table.Row>
                          <Table.Cell>{row.payment_method}</Table.Cell>
                          <Table.Cell>{row.exception_field}</Table.Cell>
                          <Table.Cell>{row.exception_value}</Table.Cell>
                          <Table.Cell>
                            {row.exception_field === 'pincode' ? (<span>NA</span>) : (<>
                              {row.included.length > 0 ? (<span style={{fontWeight:"bold"}}>{row.included.length} Pincodes included</span>) : (
                                <span>NA</span>
                              )}
                            </>)}
                            <br />
                            {row.exception_field === 'state' && row.included.length === 0 ? (
                              <span className="spanLink" onClick={() => includePinCode(row.payment_method, row.stateCode)}>Add Inclusion</span>
                            ) : (<></>)}

                            {row.exception_field === 'state' && row.included.length > 0 ? (
                              <>
                              <span className="spanLink" onClick={() => viewPincodes(row.included)}>View Inclusion | </span>
                              <span className="spanLink" onClick={() => includePinCode(row.payment_method, row.stateCode)}> Add Inclusion | </span>
                              <span className="spanLink" onClick={() => clearPincodeInclusionModal(row.payment_method, row.stateCode, row.included)}> Clear Inclusion </span>
                            </>
                            ) : (<></>)}

                          </Table.Cell>
                          <Table.Cell>
                          <div className="delete-txt-btn" onClick={() => deleteExceptionModal(row.payment_method, row.exception_field, row.exception_value)}>
                            <i className="fa fa-trash"></i> Delete
                          </div>
                          </Table.Cell>
                        </Table.Row>
                          </>
                        )

                      })}
                    </Table.Body>
                  </Table>
                </CCardBody>
              </CCard>

            </React.Fragment>) : (
                <CCard>
                    <CCardBody>
                        <div className="d-flex flex-column align-items-center">
                            <p>No restriction on payment method</p>
                        </div>
                    </CCardBody>
                </CCard>
            )}
        </CCol>

{/* create new exception modal start */}
        <CModal
        show={showAddModal}
        visible={showAddModal}
        onClose={(e) => resetState(e)}
        >
            <CModalHeader>
              New Exception
              <span style={{textAlign:"right"}} onClick={(e) => resetState(e)}>&times;</span>
            </CModalHeader>
            <CModalBody>

            <CRow>
                <CCol lg={12}>
                    <div style={{color:"#000", fontWeight:"bold"}}>Payment Method</div>
                    <span style={{color:"#ff0000"}}>{selectedPaymentError}</span>
                    <Select
                      ref={selectInputPaymentTypeRef}
                      options={paymentMethods}
                      defaultValue={selectedPaymentMethods}
                      onChange={(e)=>setSelectedPaymentMethods(e)}
                      ></Select>
                </CCol>
            </CRow>
            <br />
            <CRow>
              <CCol lg={12}>
                <div style={{color:"#000", fontWeight:"bold"}}>Exclusion for</div>
                <span style={{color:"#ff0000"}}>{exclusionForError}</span>
                <span style={{paddingRight:"20px"}}>
                  <label><Input type="radio" ref={refExclusionFor} name="exclusionFor" value="state" checked={exclusionFor === 'state'? "checked" : ''} onChange={(e) => changeExclusionFor(e)} /> State</label>
                </span>
                <span>
                  <label><Input type="radio" ref={refExclusionFor} name="exclusionFor" value="pincode" checked={exclusionFor === 'pincode'? "checked" : ''} onChange={(e) => changeExclusionFor(e)} /> Pincode</label>
                </span>
              </CCol>
            </CRow>
            <br />
            <CRow>
                <CCol lg={12}>
                  {exclusionFor === 'state' ? (
                    <>
                      <div style={{color:"#000", fontWeight:"bold"}}>State</div>
                      <span style={{color:"#ff0000"}}>{selectedStateError}</span>
                      <Select options={states} isMulti={true}
                       ref={selectInputRef}
                       value={selectedStates || ""}
                      onChange={(e) => setSelectedStates(e)}
                      ></Select>
                    </>
                  ) : (
                    <>
                      <div style={{color:"#000", fontWeight:"bold"}}>Pincode <sub>(One pincode each line)</sub></div>
                      <span style={{color:"#ff0000"}}>{selectedPincodesError}</span>
                      {invalidPincodes ? <span style={{color:"#ff0000"}}>Invalid Pincodes:- {invalidPincodes}</span> : ''}
                      <textarea id="pincodes" className="form-control" name="pincodes" cols={20} rows={5}
                      defaultValue={selectedPincodes}
                      onChange={(e) => setSelectedPincode(e.target.value)}
                      ></textarea>
                    </>
                  )}

                </CCol>
            </CRow>
            </CModalBody>
            <CModalFooter>
            <Button
                color="secondary"
                // onClick={() => {
                //     setShowAddModal(true)
                // }}
                onClick={() => saveExclusion()}
                >
                Save
                </Button>
            </CModalFooter>
        </CModal>
{/* create new exception modal end */}
{/* create pincode Inclusion modal start */}
      <CModal
        show={showAddPincodeModal}
        visible={showAddPincodeModal}
        onClose={(e) => resetIncludedPincode(e)}
        >
            <CModalHeader>New Inclusion <span style={{textAlign:"right"}} onClick={(e) => resetIncludedPincode(e)}>&times;</span></CModalHeader>
            <CModalBody>

            <CRow>
                <CCol lg={12}>
                  <div style={{color:"#000", fontWeight:"bold"}}>Pincode <sub>(One pincode each line)</sub></div>
                      <span style={{color:"#ff0000"}}>{selectedPincodesError}</span>
                      {invalidPincodes ? <span style={{color:"#ff0000"}}>Invalid Pincodes:- {invalidPincodes}</span> : ''}
                      <textarea id="Includedpincodes" className="form-control Includedpincodes" name="Includedpincodes" cols={20} rows={5}
                      defaultValue={selectedPincodes}
                      onChange={(e) => setSelectedPincode(e.target.value)}
                      ></textarea>
                      {/* <textarea id="pincodes" className="form-control" name="pincodes" cols={20} rows={5}
                      defaultValue={selectedPincodes}
                      onChange={(e) => setSelectedPincode(e.target.value)}
                      ></textarea> */}
                </CCol>
            </CRow>
            </CModalBody>
            <CModalFooter>
            <Button
                color="secondary"
                // onClick={() => {
                //     setShowAddModal(true)
                // }}
                onClick={() => savePincodeInclusion()}
                >
                Save
                </Button>
            </CModalFooter>
        </CModal>
{/* create pincode Inclusion modal end */}
{/* View pincode Inclusion modal start */}
      <CModal
        show={showViewPincodeModal}
        visible={showViewPincodeModal}
        onClose={() => setShowViewPincodeModal(false)}
        >
            <CModalHeader>Pincode Included <span style={{textAlign:"right"}} onClick={() => setShowViewPincodeModal(false)}>&times;</span></CModalHeader>
            <CModalBody>

            <CRow>
                <CCol lg={12}>
                  <div style={{color:"#000", fontWeight:"bold"}}></div>
                      <div>{selectedPincodes} </div>
                </CCol>
            </CRow>
            </CModalBody>
            <CModalFooter>

            </CModalFooter>
        </CModal>
{/* View pincode Inclusion modal end */}

{/* Delete pincode Inclusion modal start */}
<CModal
        show={showDeletePincodeModal}
        visible={showDeletePincodeModal}
        onClose={() => setShowDeletePincodeModal(false)}
        >
            <CModalHeader>Clear Pincodes <span style={{textAlign:"right"}} onClick={() => setShowDeletePincodeModal(false)}>&times;</span></CModalHeader>
            <CModalBody>

            <CRow>
                <CCol lg={12}>
                  <div style={{color:"#000", fontWeight:"bold"}}>Are you sure you want to clear pincode included ? </div>
                      <br /><div>{selectedPincodes} </div>
                </CCol>
            </CRow>
            </CModalBody>
            <CModalFooter>
            <Button
                  color="secondary"
                  style={{backgroundColor:"#ff0000", color:"#fff"}}
                  onClick={() => clearPincodeInclusion()}
                  >
                  Clear
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setShowDeletePincodeModal(false)
                  }}
                  >
                  Cancel
                </Button>
            </CModalFooter>
        </CModal>
{/* Delete pincode Inclusion modal end */}


{/* Delete exception modal start */}
<CModal
        show={showDeleteExceptionModal}
        visible={showDeleteExceptionModal}
        onClose={() => setShowDeleteExceptionModal(false)}
        >
            <CModalHeader>Delete Exception <span style={{textAlign:"right"}} onClick={() => setShowDeleteExceptionModal(false)}>&times;</span></CModalHeader>
            <CModalBody>

            <CRow>
                <CCol lg={12}>
                  <div style={{color:"#000", fontWeight:"bold", padding:"20px", textAlign:"center"}}>Are you sure you want to delete exception ? </div>
                </CCol>
            </CRow>
            </CModalBody>
            <CModalFooter>
            <Button
                  color="secondary"
                  style={{backgroundColor:"#ff0000", color:"#fff"}}
                  onClick={() => deleteException()}
                  >
                  Delete
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    setShowDeleteExceptionModal(false)
                  }}
                  >
                  Cancel
                </Button>
            </CModalFooter>
        </CModal>
{/* Delete exception modal end */}
      </CRow>



    </React.Fragment>
    )
}
export default PaymentSetting;
