import React, { useState, useEffect, useCallback, useContext, useRef } from "react";
import { AppContext } from "../../contexts/app";
import Product from "./Product";
import { toast } from "react-toastify";
import { postAPI } from "../../apiServices";
import MESSAGES from "../../message.json";
import { ImageViewer } from "../../common-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CCol, CRow, CPagination, CFormInput, CButton, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter, CCard, CCardBody, CFormText, CHeader, } from "@coreui/react";
import { Table } from "semantic-ui-react";
import { Checkbox } from "semantic-ui-react";
import Swal from "sweetalert2";
import Grid from "../../common-components/grid";
import ProductDetails from "./ProductDetails";
import ProductDetailsUpdate from "./ProductDetailsUpdate";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "../../common-components/Pagination";

let timeout = null;

const PRODUCT_TYPE = {
  "in-review": 1,
  approved: 2,
  rejected: 3,
};
const localPermits = JSON.parse(localStorage.getItem("module"));
const noop = () => {};
const Products = ({ match}) => {
  const search = useLocation().search;
  const location = useLocation();
  var {type} = useParams()
  let qpage = new URLSearchParams(search).get("pg");
  let qkeyword = new URLSearchParams(search).get("q");
  let qglobal = 0;
  let qOperator = new URLSearchParams(search).get("o");
  let qFilterType = new URLSearchParams(search).get("f");
  qglobal = new URLSearchParams(search).get("global");
  if(qpage != null && qpage > 0){
    qpage = qpage-1;
  }else{
    qpage = 0;
  }
  let shouldSearchGlobally = false;
  if(qglobal === '1'){
    shouldSearchGlobally = true;
  }

  const defaultState = {
    entries: [],
    totalEntries: 0,
    page: 0,
    recPerPage: 50,
    searchKeyword: qkeyword,
    operator:qOperator,
    filterType: qFilterType,
    loading: false,
    confirmDialog: null,
    filter: null,
    subFilter: null,
    sellersList: [],
    categoryList: [],
    subFilterSearchString: "",
    imageViewer: null,
    details: null,
    filters: [],
    shouldSearchGlobally: shouldSearchGlobally,
  };

  const { setSecondaryHeader } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const [filterPro, setFilterPro] = useState([]);
  const [keywordError, setKeywordError] = useState()
  const [operatorError, setOperatorError] = useState()
  const [filterTypeError, setFilterTypeError] = useState()
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleId == 16)
  );
  const searchRef = useRef(null);

  if(!state?.searchKeyword){
    let el = document.getElementById('searchKeywordId');
    if(el){
      el.value = "";
    }
  }


  const handleUrls = async () => {
    let data = [];
    if(state.searchKeyword && state.filterType && state.operator){
      data.push({key:'q', val:state.searchKeyword},{key:'f', val:state?.filterType},{key:'o', val:state.operator});
    }
    if(state?.page){
      data.push({key:'pg', val:state?.page+1})
    }
    if(state.shouldSearchGlobally === true){
      data.push({key:'global', val:1})
    }
    let searchUrl = '';
    data?.map((ele) => {
      searchUrl = searchUrl + ele?.key+'='+ele?.val+'&'
    })
    let lastChar = searchUrl.charAt(searchUrl.length - 1)
    if(lastChar === '&'){
      searchUrl = searchUrl.slice(0, -1)
    }
    navigate({search : searchUrl})
  }

  // useEffect(() => {
  //   window.scroll(0, 0);
  // }, [state.loading]);
  const fetchSellers = useCallback(async () => {
    const { status, payload, message } = await postAPI("/get-sellers-list", {
      page: 0,
      recPerPage: 50,
      searchKeyword: "",
    });

    if (!status) {
      if (message !== "No seller found")
        toast.error(message || MESSAGES?.sellers?.defaultApiError);
      setState((prevState) => ({
        ...prevState,
        sellersList: defaultState.sellersList,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        sellersList: payload?.data || defaultState.sellersList,
      }));
    }
  }, []);

  const fetchCategory = useCallback(async () => {
    const { status, payload } = await postAPI("/get-all-categories", {
      page: 0,
      recPerPage: 50,
      searchKeyword: "",
    });

    if (!status) {
      setState((prevState) => ({
        ...prevState,
        categoryList: defaultState.categoryList,
      }));
    } else {
      const test = (payload?.categories || defaultState.categoryList).map(
        (category) => {
          return 1;
        }
      );
      setState((prevState) => ({
        ...prevState,
        categoryList: payload?.categories || defaultState.categoryList,
      }));
    }
  }, []);

  const fetchProducts = async (
      page = 0,
      recPerPage = 50,
      searchKeyword = "",
      operator = "equals",
      filterType,
      filters = [],
      shouldSearchGlobally = state.shouldSearchGlobally,

    ) => {
      try {
        setState((prevState) => ({ ...prevState, loading: true }));
        const productPayload = { page, recPerPage, searchKeyword, operator, filterType };
        productPayload["searchGlobally"] = shouldSearchGlobally ? 1 : 0
        // if(qglobal !== null && qglobal === '1'){
        //   productPayload["shouldSearchGlobally"] = 1
        // }
        filters = filters === null ? [] : filters;
        if (filters.length) {
          productPayload["filters"] = filters;
        }
        const sectionFilter = {
          param: "product-listing-type",
          value: [PRODUCT_TYPE[type || ""]],
        };
        if (!shouldSearchGlobally) {
          if (!!productPayload.filters) {
            let duplFil = productPayload.filters.filter((re) => {
              return re.param == "product-listing-type";
            });
            if (duplFil.length == 0) {
              productPayload.filters.push(sectionFilter);
            } else {
              let filterData = productPayload.filters.filter((re) => {
                return re.param != "product-listing-type";
              });
              filterData.push(sectionFilter);
              productPayload.filters = filterData;
            }
          } else {
            productPayload["filters"] = [sectionFilter];
          }
        }
        const { status, payload, message } = await postAPI(
          "/products/list",
          productPayload
        );

        setState((prevState) => ({ ...prevState, loading: false }));
        Swal.close();

        if (!status) {
          if (message !== "No product found")
            toast.error(message || MESSAGES?.products?.defaultApiError);
          setState((prevState) => ({
            ...prevState,
            entries: defaultState.entries,
            totalEntries: defaultState.totalEntries,
          }));
        } else {
          let productData = payload?.data;
          for (let d = 0; d < productData.length; d++) {
            for (let v = 0; v < productData[d].skus.length; v++) {
              productData[d].skus[v].dimention1 =
                productData[d].skus[v].dimensionValue.split("*")[0];
              productData[d].skus[v].dimention2 =
                productData[d].skus[v].dimensionValue.split("*")[1];
              productData[d].skus[v].dimention3 =
                productData[d].skus[v].dimensionValue.split("*")[2];
            }
          }

          setState((prevState) => ({
            ...prevState,
            entries: productData || defaultState.entries,
            totalEntries: payload?.totRecords || defaultState.totalEntries,
          }));
        }
      } catch (er) {
      }
    };

  const updateStatus = useCallback(
    async (updatedStatus = {}, id, statusType = null, remarks = "") => {
      const statusPayload = { id, status: updatedStatus.key, type: statusType };
      if (remarks) {
        statusPayload["remarks"] = remarks;
      }
      var filter = state.filters ? state.filters : [];
      if (state.filters.length == 0) {
        filter = JSON.parse(window.localStorage.getItem("FILTERDATA"));
      }
      const { status, payload, message } = await postAPI(
        "/products/update-status",
        statusPayload
      );
      setState((prevState) => ({ ...prevState, loading: false }));

      if (!status) {
        toast.error(message || MESSAGES?.products?.defaultApiError);
      } else {
        if (statusType === "product-trash" || statusType === "sku-trash") {
          toast.success(message || MESSAGES?.products?.deleteSuccess);
        } else if (updatedStatus.key === 1) {
          toast.success(message || MESSAGES?.products?.updatedSuccess);
        } else {
          toast.success(message || MESSAGES?.products?.rejectedSuccess);
        }

        fetchProducts(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.operator,
          state.filterType,
          filter,
          state.shouldSearchGlobally
        );
      }
    },
    [
      fetchProducts,
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.operator,
      state.filterType,
      state.filters,
      state.shouldSearchGlobally,
    ]
  );

  const showProductDetails = (details) => {
    setState((prevState) => ({
      ...prevState,
      details,
    }));
  };

  const updateProductDetails = (updateDetails) => {
    setState((prevState) => ({
      ...prevState,
      updateDetails,
    }));
  };

  useEffect(() => {
    if(!search){
      setState((prevState) => ({
        ...prevState, filterType: null, operator: null, searchKeyword: '', page:0, shouldSearchGlobally:false
      }))
      // handleUrls();
      if(!state.loading){
        fetchProducts(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.operator,
          state.filterType,
          state.filters,
          state.shouldSearchGlobally,
        );
      }
    }
  }, [search, type, state.recPerPage])

  useEffect(() => {
      handleUrls()
      if(search){
        fetchProducts(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.operator,
          state.filterType,
          state.filters,
          state.shouldSearchGlobally,
        );
      }

  }, [state.page, search]);

  useEffect(() => {
    fetchSellers();
    fetchCategory();
  }, []);

  const handleData = async (e) => {
    setKeywordError()
    setOperatorError()
    setFilterTypeError()
    let name = e.target.name;
    let value = e.target.value;
    if(name==='filterType'){
      await setState((prevState) => ({
        ...prevState, 'filterType':value
      }));
      if(value){
        document.getElementById("filterType").style.borderColor = ""
      }
    }else if(name === 'operator'){
      await setState((prevState) => ({
        ...prevState, 'operator':value
      }));
      if(value){
        document.getElementById("operator").style.borderColor = ""
      }
    }
  }

  const skuColumnConfig = [
    { label: "SKU Code", field: "skuCode" },
    { label: "Color Name", field: "colorName" },
    {
      label: "Orginal Color Name",
      field: "origColorName",
      render: (row) => (
        <p>
          {row.origColorCode} ({row.origColorName})
        </p>
      ),
    },
    { label: "Package Weight", field: "packageWeight" },
    { label: "Dimension Value", field: "dimensionValue" },
    { label: "Price Per Item", field: "pricePerItem" },
    { label: "S-Cash", field: "loyalty_points" },
  ];
  const handleToggle = () => {
    if (state.shouldSearchGlobally) {
      setState((prevState) => ({
        ...prevState,
        shouldSearchGlobally: (state.shouldSearchGlobally = true),
      }));
    }
  };

  const onUpdateProducts = (action) => {
    if(action?.close !== 'Close'){
      fetchProducts()
    }

    setState((prevState) => ({
      ...prevState,
      updateDetails: null,
      details: null,
    }));
  }

  useEffect(() => {
    setSecondaryHeader([renderSecondaryHeader()]);
    return () => setSecondaryHeader([]);
  }, [
    state.searchKeyword,
    state.recPerPage,
    state.operator,
    state.filterType,
    state.shouldSearchGlobally,
  ]);

  if (state.loading) {
    Swal.fire({
      title: "Loading...",
      timerProgressBar: false,
      allowOutsideClick: false,
      showCancelButton:false,
      showConfirmButton:false

    })
  }

  const resetFilter = () => {
    // history.location.pathname
    setState(defaultState);
    // navigate({ pathname: navigate.location.pathname})
    navigate(location.pathname, { replace: true });
  }

  const handleGlobal = () => {
    setState((prevState) => ({
      ...prevState,
      shouldSearchGlobally: !prevState.shouldSearchGlobally,
    }))
  }

  const renderSecondaryHeader = () => {

    return (
  <></>

    );
  };

  const handlePagination = (pgInfo) => {
    setState((prevState) => ({
      ...prevState,
      recPerPage: parseInt(pgInfo?.recPerPage),
      page: pgInfo?.page
    }));
  }


  return (
    <>
    <CHeader>
      <CRow>
        <CCol>
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
          <form
            id="filter-form"
            className="d-flex flex-column flex-sm-row align-items-center flex-wrap w-100 searchForm"
            onFinish={console.log}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                setState((prevState) => ({
                  ...prevState,
                  page: 0,
                }));
                e.preventDefault();
                setKeywordError()
                setOperatorError()
                setFilterTypeError()
                handleUrls()
              }
            }}>

        <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
          <select className="form-control" name="filterType"
            onChange={(e) => handleData(e)}
            id="filterType"
            style={{borderColor: filterTypeError ? "red" : ""}}
            >
              <option value="" selected={state.filterType==='' ? true :false}>Select</option>
              <option value="title" selected={state.filterType==='title' ? true :false}>Title</option>
              <option value="seller" selected={state.filterType==='seller' ? true :false}>Seller</option>
              <option value="sku" selected={state.filterType==='sku' ? true :false}>SKU</option>
              <option value="category" selected={state.filterType==='category' ? true :false}>Category</option>
              <option value="brand" selected={state.filterType==='brand' ? true :false}>Brand</option>
            </select>
          </div>
          <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
            <select className="form-control" id="operator" name="operator" onChange={(e) => handleData(e)} style={{borderColor: operatorError ? "red" : ""}}>
            <option value="" selected={state.filterType==='' ? true :false}>Select</option>
            <option value={"equals"} selected={state.operator==='equals' ? true :false}>Equals</option>
            <option value={"contains"} selected={state.operator==='contains' ? true :false}>Contains</option>
          </select>
          </div>
          <div className="d-flex align-items-center mb-2 mb-sm-0 me-sm-2">
          <input
            type="text"
            id="searchKeywordId"
            className="form-control w-100"
            placeholder="Product, name, category, and brand"
            defaultValue={state?.searchKeyword}
            style={{borderColor: keywordError ? "red" : ""}}
            onChange={(evt) => {
              const { value } = evt.currentTarget;
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                setState((prevState) => ({
                  ...prevState,
                  searchKeyword: (value || "").trim(),
                }));
              });
            }}
          />

        </div>
        <div className="d-flex align-items-center w-20">
        <Checkbox
            id="isGlobal"
            label="Search Globally"
            // value={state.shouldSearchGlobally}
            checked={state.shouldSearchGlobally}
            className="ms-4 searchGloballyText"
            onChange={(evt) => handleGlobal(evt)}
          />
          </div>
        <div className="ms-2 d-flex align-item-center w-10">
        <CButton
            color="primary"
            className="ms-1"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                page: 0,
              }));
              document.getElementById("searchKeywordId").style.borderColor = ""
              document.getElementById("filterType").style.borderColor = ""
              document.getElementById("operator").style.borderColor = ""
              setKeywordError()
              setOperatorError()
              setFilterTypeError()
              if((state.searchKeyword && state.operator && state.filterType) || state.shouldSearchGlobally){
                handleUrls()
                // fetchProducts(
                //   state.page,
                //   state.recPerPage,
                //   state.searchKeyword,
                //   state.operator,
                //   state.filterType,
                //   state.shouldSearchGlobally
                // );
              }else{

                if(!state.searchKeyword){
                  document.getElementById("searchKeywordId").style.borderColor = "red"
                  setKeywordError("Required")
                }
                if(!state.operator){
                  document.getElementById("operator").style.borderColor = "red"
                  setOperatorError("Required")
                }
                if(!state.filterType){
                  document.getElementById("filterType").style.borderColor = "red"
                  setFilterTypeError("Required")
                }
              }
            }}
            // onClick={() => filterData()}
          >
            <FontAwesomeIcon icon={faSearch} style={{ cursor: "pointer" }} />
          </CButton>

          {state.searchKeyword && state.operator && state.filterType &&
            <>
            <CButton
              color="danger"
              className="ms-1"
              onClick={()=>resetFilter()}
              // onClick={() => filterData()}
            >
              Clear
            </CButton>
            </>
          }

        </div>

        </form>
      </div>
        </CCol>
      </CRow>
    </CHeader>
      <CRow>
        <CCol lg={12}>
          {!!state.entries.length && (
                <Pagination handlePagination={handlePagination} state={state} />
              )}
          {!state.loading && !state.entries.length ? (
            <>
              <div className="d-flex flex-column align-items-center">

                <h4>No Product(s) found</h4>

              </div>
            </>
          ) : (
            <>
              <CCard>
                <CCardBody>
                  <Table basic="very" style={{ borderCollapse: "separate" }}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ border: 0 }}>
                          S.No
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Product Details
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          <div className="d-flex align-items-center justify-content-center">
                            Action
                          </div>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {state.entries.map((entry, index) => (
                        <Product
                          product={entry}
                          section={entry?.currentStatus}
                          index={index}
                          onStatusChange={(
                            updatedStatus = {},
                            statusType,
                            id,
                            action,
                            entryName,
                            callback = () => {}
                          ) => {
                            setState((prevState) => ({
                              ...prevState,
                              confirmDialog: {
                                // statusType: "product-approval",
                                statusType,
                                updatedStatus,
                                id,
                                action,
                                entryName,
                                callback,
                              },
                            }));
                          }}
                          onDelete={(id, statusType, action, entryName) => {
                            setState((prevState) => ({
                              ...prevState,
                              confirmDialog: {
                                statusType,
                                updatedStatus: { key: 1 },
                                id,
                                action,
                                entryName,
                              },
                            }));
                          }}
                          showImageViewer={(images) => {
                            setState((prevState) => ({
                              ...prevState,
                              imageViewer: { images },
                            }));
                          }}
                          showProductDetails={showProductDetails}
                          updateProductDetails={updateProductDetails}
                        />
                      ))}
                    </Table.Body>
                  </Table>
                </CCardBody>
              </CCard>
              {!!state.entries.length && (
                <Pagination handlePagination={handlePagination} state={state} />
              )}
            </>
          )}
        </CCol>
      </CRow>

      <CModal
        alignment="center"
        // show={!!state.confirmDialog}
        visible={!!state.confirmDialog}
        onClose={() => {
          setState((prevState) => ({ ...prevState, confirmDialog: null }));
        }}
      >
        <CModalHeader>
          <CModalTitle>
            <CModalTitle>{`${state.confirmDialog?.action} - ${state.confirmDialog?.entryName}`}</CModalTitle>
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <p>Are you sure you want to continue?</p>
          {((state.confirmDialog?.statusType === "product-approval" &&
            state.confirmDialog?.updatedStatus.key === 2) ||
// state.confirmDialog?.statusType === "sku-status" ||
            state.confirmDialog?.statusType === "sku-trash") && (
            <div className="d-flex w-100">
              <textarea
                value={state.confirmDialog?.remarks || ""}
                className="w-75"
                placeholder="Type remarks here..."
                rows={4}
                maxLength={255}
                onChange={(evt) => {
                  const { value } = evt.currentTarget;
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: {
                      ...prevState.confirmDialog,
                      remarks: value,
                    },
                  }));
                }}
              ></textarea>
            </div>
          )}
          <CFormText color="link" className="help-block mb-1 validation">
            {state.confirmDialog?.remarks?.length > 254 && "Max length: 255"}
          </CFormText>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({ ...prevState, confirmDialog: null }));
            }}
          >
            Cancel
          </CButton>
          {(state.confirmDialog?.remarks ||
            state.confirmDialog?.updatedStatus.key == "0" ||
            state.confirmDialog?.updatedStatus.key === "2") && (
            <CButton
              color={
                state.confirmDialog?.statusType.indexOf("trash") > -1
                  ? "danger"
                  : "primary"
              }
              onClick={() => {
                updateStatus(
                  state.confirmDialog?.updatedStatus,
                  state.confirmDialog?.id,
                  state.confirmDialog?.statusType,
                  state.confirmDialog?.remarks
                );
                if (state.confirmDialog?.callback) {
                  state.confirmDialog.callback();
                }
                setState((prevState) => ({
                  ...prevState,
                  confirmDialog: null,
                }));
              }}
            >
              Ok
            </CButton>
          )}
          {state.confirmDialog?.updatedStatus.key == 0 ||
            (state.confirmDialog?.updatedStatus.key == 1 && (
              <CButton
                color={
                  state.confirmDialog?.statusType.indexOf("trash") > -1
                    ? "danger"
                    : "primary"
                }
                onClick={() => {
                  updateStatus(
                    state.confirmDialog?.updatedStatus,
                    state.confirmDialog?.id,
                    state.confirmDialog?.statusType,
                    state.confirmDialog?.remarks
                  );
                  if (state.confirmDialog?.callback) {
                    state.confirmDialog.callback();
                  }
                  setState((prevState) => ({
                    ...prevState,
                    confirmDialog: null,
                  }));
                }}
              >
                Ok
              </CButton>
            ))}
        </CModalFooter>
      </CModal>

      <ImageViewer
        open={!!state.imageViewer}
        images={(state.imageViewer?.images || []).map((image) => ({
          src: image,
        }))}
        onClose={() => {
          setState((prevState) => ({ ...prevState, imageViewer: null }));
        }}
      />

      {state.details ? (<>
        <CModal
        centered
        alignment="center"
        // show={!!state.details}
        visible={!!state.details}
        size="lg"
        scrollable={true}
        backdrop={"static"}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            details: null,
          }));
        }}
      >
        <CModalBody>
          {state.details?.detailType}
          {state.details?.detailType === "sku" ? (
            <Grid data={[{ ...state.details }]} config={skuColumnConfig} />
          ) : (
            <ProductDetails product={state.details || {}} />
          )}
        </CModalBody>
        <CModalFooter>
          <CButton
            color="primary"
            className="ms-1 me-1"
            onClick={() => updateProductDetails(state.details)}
            style={{ cursor: "pointer", width: "117px" }}
          >
            Edit Product
          </CButton>
          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                details: null,
              }));
            }}
          >
            Close
          </CButton>
        </CModalFooter>
      </CModal>
      </>) : (<></>)}


      <CModal
        centered
        alignment="center"
        // show={!!state.updateDetails}
        visible={!!state.updateDetails}
        size="lg"
        scrollable={true}
        backdrop={"static"}
        onClose={() => {
          setState((prevState) => ({
            ...prevState,
            updateDetails: null,
          }));
        }}
      >
        <CModalBody>
          {state.updateDetails?.detailType === "sku" ? (
            <Grid
              data={[{ ...state.updateDetails }]}
              config={skuColumnConfig}
            />
          ) : (
            <ProductDetailsUpdate
              productUpdate={state.updateDetails || {}}
              // onUpdateProduct={(filters) => {
              //   setState((prevState) => ({
              //     ...prevState,
              //     updateDetails: null,
              //   }));
              // }}
              onUpdateProduct={onUpdateProducts}
            />
          )}
        </CModalBody>
        {/* <CModalFooter>
          <CButton
            color="primary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                updateDetails: null,
              }));
            }}
          >
            Update
          </CButton>

          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                updateDetails: null,
              }));
            }}
          >
            Close
          </CButton>

        </CModalFooter> */}
      </CModal>
    </>
  );
};

export default Products;
