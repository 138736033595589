import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useContext,
  } from "react";

import {
    CCard,
    CCardBody,
    CCardText,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CInput,
    CBadge,
    CButton
} from "@coreui/react";
import he from 'he';
import { Button, Table, Dropdown } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import { get, getApiCall, postAPI } from "../../apiServices";
import { amountRegex } from "../../constants/regexValidation";
import { toast } from "react-toastify";

const defaultState = {
    selectedItem: [],
  };

  const CreditDebitNotePreview = ({ entry, noteType, notePreviewModal, toggelNotePreviewModal}, props) => {
    const [shwoNotePreviewModal, setShowNotePreviewModal] = useState();
    const [creditNotePreview, setCreditNotePreview] = useState(<div><h1>test</h1><p>new paragraph<sup>2</sup></p></div>);

    useEffect(() => {
        getPreviewData()

    },[notePreviewModal])

    const handleViewNotePreviewModalClose = () => {
        setShowNotePreviewModal(false);
        toggelNotePreviewModal();
    }

    const getPreviewData = async() => {
        let type = 'debit-note';
        let returnId = entry?.returnId;
        if(noteType === 'creditNote'){
            type = 'credit-note';
        }
        let res = await getApiCall('/return/'+returnId+'/'+type+'?viewType=preview')
        if(res.status === 1){
            if (!res.payload.data) {
                return null; // or some loading state or default content
              }
            const decodedHTML = he.decode(res.payload.data);
            setCreditNotePreview(decodedHTML);
            setShowNotePreviewModal(notePreviewModal)
        }else{
            toast.error(res?.message);
            handleViewNotePreviewModalClose()
        }
    }

    const generateNote = async() => {
        let type = 'debit-note';
        let returnId = entry?.returnId;
        if(noteType === 'creditNote'){
            type = 'credit-note';
        }
        let res = await postAPI('/return/'+returnId+'/generate/'+type);
        if(res?.status === 1){
            toast.success(res?.message);
            handleViewNotePreviewModalClose()
        }else{
            toast.error(res?.message);
        }
    }

    return (<>
    <Modal show={shwoNotePreviewModal} onHide={handleViewNotePreviewModalClose} backdrop="static" keyboard={false} size="lg">
        <Modal.Header>
            <Modal.Title>{noteType === 'creditNote' ? 'Credit Note' : 'Debit Note'} Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div style={{height:"500px", overflow:"scroll"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-2">
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: creditNotePreview }} />
                        </div>
                    </div>
                </div>

            </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button className="btn btn-danger" onClick={handleViewNotePreviewModalClose}>  Cancel </Button>
            <Button className="btn blue btn-primary btn-md" onClick={() => generateNote()}> Generate &amp; Download </Button>
            {/* {!cancelConfirmVisible ? (<>
              <Button className="btn btn-danger" onClick={handleViewTransnModalClose}>  Cancel </Button>
              <Button className="btn blue btn-primary btn-md"  onClick={markComplete}> Submit </Button>
              <span style={{color:"red"}}>{errorMessage}</span>
            </>) : (<>

                <Button className="btn btn-danger" onClick={rejectCancel}>  No </Button>
                <Button className="btn blue btn-primary btn-md"  onClick={acceptCancel}> Yes </Button>
                <div>
                    <p style={{color:"red"}}><strong>{cancelMessageTitle}</strong> {" "}{cancelMessageText}</p>
                </div>
            </>)} */}
        </Modal.Footer>
    </Modal>
    </>)

  }
  export default CreditDebitNotePreview
