import React, {
    useState,
    useEffect,
    useRef,
    useContext,
  } from "react";
  import { AppContext } from "../../contexts/app";
  import { toast } from "react-toastify";
  import { postAPI, putAPI, getApiCall } from "../../apiServices";
  import MESSAGES from "../../message.json";
  import { Checkbox } from "semantic-ui-react";
  import { Button, Popup } from "semantic-ui-react";
  import { CCol, CRow, CFormInput, CButton, CHeader } from "@coreui/react";
  import Swal from "sweetalert2";
  import NoDataIcon from "../../assets/icons/no-data.svg";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faDownload, faInfoCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
  import ReturnList from "./ReturnList";
  import { useNavigate, useLocation, useParams } from "react-router-dom";
  import Pagination from "../../common-components/Pagination";
  import DatePicker from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css";
  import { format } from 'date-fns';

  import CreateReturn from "./CreateReturn";
  import download from "downloadjs";

  const localPermits = JSON.parse(localStorage.getItem("module"));
  function Returns({ match }) {
    const navigate = useNavigate();
    const filterSubmitBtnRef = useRef();
    const {type} = useParams()
  var urlPar = type;
  const location = useLocation();
  const search = useLocation().search;
  let qglobal = 2;
  let qpage = new URLSearchParams(search).get("pg");
  let qkeyword = new URLSearchParams(search).get("q");
  let qFilterType = new URLSearchParams(search).get("filterType");
  let qStartDate = null;
  qStartDate = new URLSearchParams(search).get("startDate");
  let qEndDate = null;
  qEndDate = new URLSearchParams(search).get("endDate");
  let qSubFilterType = new URLSearchParams(search).get("subFilterType");
  qglobal = new URLSearchParams(search).get("global");
  if(qpage != null && qpage > 0){
    qpage = qpage-1;
  }else{
    qpage = 0;
  }

  let rangeStartD = null;
  let rangeEndD = null;
  if(qStartDate != null){
    const parts = qStartDate.split('-');
    const dayS = parseInt(parts[0], 10);
    const monthS = parseInt(parts[1], 10); // Months are 0-based
    const yearS = parseInt(parts[2], 10);
    rangeStartD = monthS+"-"+dayS+"-"+yearS;

  }

  if(qEndDate != null){
    const partE = qEndDate.split('-');
    const dayE = parseInt(partE[0], 10);
    const monthE = parseInt(partE[1], 10); // Months are 0-based
    const yearE = parseInt(partE[2], 10);
    rangeEndD = monthE+"-"+dayE+"-"+yearE;
  }

  let shouldSearchGlobally = false;
  if(qglobal === 1){
    shouldSearchGlobally = true;
  }

  let timeout = null;
  const defaultState = {
    entries: [],
    totalEntries: 0,
    page: qpage,
    recPerPage: 50,
    searchKeyword: qkeyword,
    startDate: qStartDate,
    endDate: qEndDate,
    filterType: qFilterType,
    subFilterType: qSubFilterType,
    searchGlobally: 0,
    action: "deliver",
    loading: true,
    confirmDialog: null,
    shouldSearchGlobally: shouldSearchGlobally,
    showClearFilter: search ? true : false,
  };

let filterOpt = []
    const { setSecondaryHeader } = useContext(AppContext);
    const [state, setState] = useState(defaultState);
    const searchRef = useRef(null);
    const [createRetrun, setCreateRetrun] = useState(false)
    const [filterOptions, setFilterOptions] = useState();
    const [subFilterOptions, setSubFilterOptions] = useState();
    const [keywordError, setKeywordError] = useState()
    const [operatorError, setOperatorError] = useState()
    const [filterTypeError, setFilterTypeError] = useState()
    const [permissions, setPermissions] = useState(localPermits.find((o) => o.moduleName === "Return & Refund"));
    const [rangeStart, setRangeStart] = useState(rangeStartD != null ? new Date(rangeStartD) : null);
    const [rangeEnd, setRangeEnd] = useState(rangeEndD != null ? new Date(rangeEndD) : null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const handleUrls = async () => {
      let data = [];
      if(state.searchKeyword){
        data.push( {key:'q', val:state.searchKeyword});
      }
      if(state?.startDate){
        setRangeStart(new Date(state.startDate))
        data.push({key:'startDate', val:state.startDate})
      }
      if(state?.endDate){
        setRangeEnd(new Date(state.endDate))
        data.push({key:'endDate', val:state.endDate})
      }
      if(state?.filterType){
        data.push({key:'filterType', val:state.filterType})
      }
      if(state?.subFilterType){
        data.push({key:'subFilterType', val:state.subFilterType})
      }
      if(state?.page){
        data.push({key:'pg', val:state?.page+1})
      }
      if(state.shouldSearchGlobally === true){
        data.push({key:'global', val:1})
      }
      let searchUrl = '';
      data?.map((ele) => {
        searchUrl = searchUrl + ele?.key+'='+ele?.val+'&'
      })
      let lastChar = searchUrl.charAt(searchUrl.length - 1)
      if(lastChar === '&'){
        searchUrl = searchUrl.slice(0, -1)
      }
      navigate({search : searchUrl})
    }

    useEffect(() => {
      async function getFilterList() {
        let filters = await getApiCall('/return/filters');
        if(filters.status === 1){
          filterOpt = filters.payload;
          var x = document.getElementById("filter");
          let length = x?.length;
          while (length > 0) {
            x.remove(length);
            length = length - 1;
          }
          filterOpt.forEach(element => {
            var option = document.createElement("option");
            option.value = JSON.stringify(element);
            option.text = element.label;
            option.selected = element.value === state.filterType ? true : false;
            x?.add(option);
          });
          // setLoading(false)
        }
      }
      getFilterList()
    }, [])

    useEffect(() => {
      if(location?.pathname === '/return&refund-due'){
        setState((prevState) => ({
          ...prevState,
          status: 0,
          section: "initiate",
        }))
      }else if(location?.pathname === '/return&refund-approved'){
        setState((prevState) => ({
          ...prevState,
          status: 1,
          section: "approved",
        }))
      }else if(location?.pathname === '/return&refund-rejected'){
        setState((prevState) => ({
          ...prevState,
          status: 2,
          section: "rejected",
        }))
      }else if(location?.pathname === '/return&refund-settlement'){
        setState((prevState) => ({
          ...prevState,
          status: 3,
          section: "settlement",
        }))
      }else if(location?.pathname === '/return&refund-completed'){
        setState((prevState) => ({
          ...prevState,
          status: 4,
          section: "completed",
        }))
      }else if(location?.pathname === '/return&refund-cancelled'){
        setState((prevState) => ({
          ...prevState,
          status: 5,
          section: "cancelled",
        }))
      }
    },[location?.pathname])

    const fetchReturns = async (
      page = qpage,
      recPerPage,
      searchKeyword = "",
      startDate="",
      endDate="",
      filterType,
      subFilterType,
      shouldSearchGlobally = false) => {
        let fstatus = 0;
        let fsection = 'initiate'
        if(location?.pathname === '/return&refund-due'){
          fstatus = 0;
          fsection = 'initiate'
        }else if(location?.pathname === '/return&refund-approved'){
          fstatus = 1;
          fsection = 'approved'
        }else if(location?.pathname === '/return&refund-rejected'){
          fstatus = 2;
          fsection = 'rejected'
        }else if(location?.pathname === '/return&refund-settlement'){
          fstatus = 3;
          fsection = 'settlement'
        }else if(location?.pathname === '/return&refund-completed'){
          fstatus = 4;
          fsection = 'completed'
        }else if(location?.pathname === '/return&refund-cancelled'){
          fstatus = 5;
          fsection = 'cancelled'
        }
        setState((prevState) => ({ ...prevState, entries: [], totalEntries: 0 }));
        setState((prevState) => ({ ...prevState, loading: true }));
        let filters = [];
      if(Math.floor(new Date(state.startDate+' 00:00:00').getTime()/1000) > 0 || Math.floor(new Date(state.endDate+' 23:59:59').getTime()/1000) >0){
          filters.push({param:"date-range", value:{
            start: Math.floor(new Date(state.startDate+' 00:00:00').getTime()/1000),
            end: Math.floor(new Date(state.endDate+' 23:59:59').getTime()/1000),
        }})
      }
      let f = document.getElementById('filter');
      let filterval = f?.value;
      let selectedFilterVal;
      let selectedSubFilterVal;
      if(filterval){
        selectedFilterVal = JSON.parse(filterval)?.value
      }
      let s;
      if(filterval && JSON.parse(filterval)?.type == 'default'){
        s = document.getElementById('subfilter');
        let subfilterval = s?.value;
        if(subfilterval){
          selectedSubFilterVal = JSON.parse(subfilterval)?.value;
        }
      }else{
        s = document.getElementById('subfilterTxt');
        selectedSubFilterVal = s?.value;
      }

      if(selectedFilterVal && selectedSubFilterVal){
        filters.push({param:state.filterType, value:state.subFilterType})
      }
         const data = {
          page,
          recPerPage : recPerPage ? recPerPage :50,
          // action: defaultState.action,
          searchKeyword,
          startDate,
          endDate,
          filterType,
          subFilterType,
          searchGlobally: shouldSearchGlobally ? 1 : 0,
          status: fstatus,
          // filters: filters,
          // action : urlPar,
        };
        data.action = urlPar;
        const { status, payload, message } = await postAPI("/return/list", data);
        setState((prevState) => ({ ...prevState, loading: false }));

        Swal.close();
        if (!status) {
          if (message !== "No Data found")
            toast.error(message || MESSAGES?.sellers?.defaultApiError);
          setState((prevState) => ({
            ...prevState,
            entries: defaultState.entries,
            totalEntries: defaultState.totalEntries,
          }));
        } else {
          setState((prevState) => ({
            ...prevState,
            entries: payload?.data || defaultState.entries,
            totalEntries: payload?.totalRecords || defaultState.totalEntries,
            loading: false
          }));

        }
        window.scrollTo(0, 0);
      };

    // const fetchReturns = async (
    //   page = 0,
    //   recPerPage = 50,
    //   searchKeyword = "",
    //   shouldSearchGlobally = 2
    // ) => {
    //   if(state.status != null){
    //     let getComOBJ = {
    //       recPerPage,
    //       page,
    //       status: state.status,
    //       returnId: "",
    //       searchGlobally : shouldSearchGlobally ? shouldSearchGlobally : 2,
    //       searchKeyword,
    //     };
    //     const { status, payload, message } = await postAPI(
    //       "/return/list",
    //       getComOBJ
    //     );
    //     Swal.close();

    //     if (!status) {
    //       if (message !== "No Dues found")
    //         toast.error(message || MESSAGES?.sellers?.defaultApiError);
    //       setState((prevState) => ({
    //         ...prevState,
    //         entries: defaultState.entries,
    //         totalEntries: defaultState.totalEntries,
    //         loading:false
    //       }));
    //     } else {
    //       setState((prevState) => ({
    //         ...prevState,
    //         entries: payload?.data || defaultState.entries,
    //         totalEntries: payload?.totalRecords || defaultState.totalEntries,
    //         loading: false
    //       }));
    //     }
    //   }

    // };
    // useEffect(() => {
    //   fetchReturns(
    //     state.page,
    //     state.recPerPage,
    //     state.searchKeyword,
    //     state.shouldSearchGlobally
    //   );
    // }, [state.page, state.recPerPage, state.status]);

    const updateStatus = async (status, payload) => {
      let res = await putAPI('/return/update-status', payload);
      if(res.status === 1){
        toast.success(res.message);
        fetchReturns(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.shouldSearchGlobally,
        );
      }else{
        toast.error(res.message);
      }

    }


  const changeFilter = async (e) => {
    let value;
    let type = 'default';
    let fType = '';
    // let fType = JSON.parse(e.target.value).value;
    if(e.target.value){
      value = JSON.parse(e.target.value).value;
      fType = JSON.parse(e.target.value).value;
      type = JSON.parse(e.target.value).type
    }
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        filterType:fType,
        showClearFilter: true,
        searchKeyword: "",
        subFilterType: "",
      }));
    })

    if(type==='default'){
      document.getElementById('subfilterTxt').style.display = "none"
      document.getElementById('subfilter').style.display = "flex"
      let data = {type:value}
      var subfilters = await postAPI('/orders/sub-filters',data);
      if(subfilters.status === 1){
        filterOpt = subfilters.payload;
        var x = document.getElementById("subfilter");
        let length = x?.length;
        while (length > 0) {
          x.remove(length);
          length = length - 1;
        }
        filterOpt.forEach(element => {
          var option = document.createElement("option");
          option.value = JSON.stringify(element);
          option.text = element.label;
          x?.add(option);
        });
        // await setSubFilterOptions(subfilters.payload);
      }
      else{
        if(subfilters.payload.length === 0){
          document.getElementById('subfilterTxt').value = ""
          var x = document.getElementById("subfilter");
          let length = x.length;
          while (length > 0) {
            x.remove(length);
            length = length - 1;
          }
        }
      }
    }else{
      document.getElementById('subfilter').style.display = "none"
      document.getElementById('subfilterTxt').style.display = "flex"
      document.getElementById('subfilterTxt').type = type ? type.split('#')[1] : 'number';
      document.getElementById('subfilterTxt').min = 0;
      document.getElementById('subfilterTxt').pattern = "[0-9]";
      document.getElementById('subfilterTxt').max = 99;
    }
  }

  const subfilterTxtHandle = (e) => {
    let value = e.target.value;
    setState((prevState) => ({
      ...prevState,
      showClearFilter: true,
      subFilterType: value,
    }));
  }

  const resetFilter = () => {
    setState((prevState) => ({
      ...prevState,
      startDate: "",
      endDate: "",
      searchKeyword: "",
      filterType: "",
      subFilterType: "",
      showClearFilter:false,
      shouldSearchGlobally:false,
      filters: [],
    }));
    changeDateRange([null,null]);
    document.getElementById("filter").value = "";
    document.getElementById("subfilter").value = "";
    document.getElementById("serchKey").value = "";
    document.getElementById("subfilterTxt").value = "";
    // history.replace({ pathname: history.location.pathname})
    navigate({ pathname: location?.pathname})
  }

    useEffect(() => {
      setSecondaryHeader([renderSecondaryHeader()]);
      return () => setSecondaryHeader([]);
    }, [
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.startDate,
      state.endDate,
      state.filterType,
      state.subFilterType,
      state.shouldSearchGlobally,
      state.showClearFilter,
      location?.pathname
    ]);

    useEffect(() => {
      // clearFilter()
      changeDateRange([null,null]);
      fetchReturns()
    },[location?.pathname])

    const changeDateRange = (update) => {
      setDateRange(update);
      let s = "";
      let e = "";
      if(update[0] != null){
        s = format(new Date(update[0]), 'd-MM-yyyy').toString()
        setState((prevState) => ({
          ...prevState,
          startDate: (s),
          endDate: (e),
          showClearFilter: true
        }));
      }
      if(update[1] != null){
        e = format(new Date(update[1]), 'd-MM-yyyy').toString()
        setState((prevState) => ({
          ...prevState,
          startDate: (s),
          endDate: (e),
          showClearFilter: true
        }));
      }
      clearTimeout(timeout);
          setState((prevState) => ({
            ...prevState,
            startDate: (s),
            endDate: (e),
            showClearFilter: true
          }));
    }

    useEffect(() => {
      if(!search){
        setState((prevState) => ({
          ...prevState, filterType: "", subFilterType: "", searchKeyword: '', startDate:"", endDate: "", page:0, showClearFilter: false, filters: [], shouldSearchGlobally: false
        }))
        clearFilter();
        if(!state.loading){
          fetchReturns(0, state.recPerPage, "", "", "", "", "", "", 2);
        }
      }
    }, [search,location?.pathname, state.recPerPage])

    // With Filters
    useEffect(() => {
      handleUrls()
      if(search){
        fetchReturns(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.startDate,
          state.endDate,
          state.filterType,
          state.subFilterType,
          state.shouldSearchGlobally,
        );
      }

  }, [search, state?.page]);

  const clearFilter = () => {
    // setLoading(true)
    let sDate = document?.getElementById("startDate");
    let eDate = document?.getElementById("endDate");
    let sKey = document.getElementById("serchKey");
    let sFtext = document.getElementById("subfilterTxt");
    var x = document.getElementById("subfilter");
    if(x){
      let length = x.length;
      while (length > 0) {
        x.remove(length);
        length = length - 1;
      }
      var option = document.createElement("option");
      option.value = "";
      option.text = "Select";
      x.add(option);
    }
    if(sDate?.value){
      sDate.value = "";
    }
    if(eDate?.value){
      eDate.value = "";
    }
    if(sKey?.value){
      sKey.value = "";
    }
    if(sFtext?.value){
      sFtext.value = "";
    }

    setState((prevState) => ({
      ...prevState,
      startDate: "",
      endDate: "",
      searchKeyword: "",
      filterType: "",
      subFilterType: "",
      showClearFilter:false,
      shouldSearchGlobally:false,
      filters: [],
    }));
  }

  const downloadCSV = async () => {
    let filters = [];
    if(Math.floor(new Date(state.startDate+' 00:00:00').getTime()/1000) > 0 ||
    Math.floor(new Date(state.endDate+' 23:59:59').getTime()/1000) >0){
      filters.push({param:"date-range", value:{
        start: Math.floor(new Date(state.startDate+' 00:00:00').getTime()/1000),
        end: Math.floor(new Date(state.endDate+' 23:59:59').getTime()/1000),
    }})
    }
    let f = document.getElementById('filter');
    let filterval = f?.value;
    let selectedFilterVal;
    let selectedSubFilterVal;
    if(filterval){
      selectedFilterVal = JSON.parse(filterval)?.value
    }
    let s;
    if(filterval && JSON.parse(filterval)?.type == 'default'){
      s = document.getElementById('subfilter');
      let subfilterval = s?.value;
      if(subfilterval){
        selectedSubFilterVal = JSON.parse(subfilterval)?.value;
      }
    }else{
      s = document.getElementById('subfilterTxt');
      selectedSubFilterVal = s?.value;
    }

    if(selectedFilterVal && selectedSubFilterVal){
      filters.push({param:selectedFilterVal, value:selectedSubFilterVal})
    }
    // let sG = state.shouldSearchGlobally ? 1 : 2
    // if(sG === 1 && permissions?.childItems?.length < 10){
    //   let msg = 'Your download has started. The downloaded file will contain orders in ';
    //   let count = 0;
    //   permissions?.childItems?.map((child, i) => {
    //     if(child?.isView === 1){
    //       msg =  msg + (i > 0 ? ', ': '') + child?.moduleName;
    //       count++;
    //     }
    //   })
    //   msg = msg + ' state';
    //   if(count > 1){
    //     msg = msg+'s'
    //   }
    //   toast.success(msg);
    // }

    const response = await postAPI("/report/returns", {
      searchKeyword: state.searchKeyword !== "" ? state.searchKeyword : "",
      page:state.page,
        recPerPage : state.recPerPage ? state.recPerPage :50,
        startDate:state.startDate,
        endDate:state.endDate,
        searchGlobally: state.shouldSearchGlobally ? 1 : 0,
        filterType: state.filterType,
        subFilterType: state.subFilterType,
        status: state.status,
    });

    let urlPar = "return_"+state.section;
    if(state.shouldSearchGlobally){
      urlPar = "return_";
    }

    download(response, urlPar+'_'+format(new Date(), 'd-MM-yyyy kk:mm').toString()+".csv", "text/csv");
  };


    const renderSecondaryHeader = () => {
      const test = (e) =>{
        // let a = document.getElementById("dateRange");
        // document.getElementById("filterSubmitBtnRef").focus();
      }
      return (
        <>

        </>
      );
    };
    // useEffect(() => {
    //   setSecondaryHeader([renderSecondaryHeader()]);
    //   return () => setSecondaryHeader([]);
    // }, [state.searchKeyword, state.recPerPage, state.shouldSearchGlobally]);

    useEffect(() => {
    try {
    if (searchRef.current) {
    setState((prevState) => ({ ...prevState, searchKeyword: "" }));
    setState((prevState) => ({
    ...prevState,
    shouldSearchGlobally: false,
    }));
    setState((prevState) => ({ ...prevState, page: 0 }));
    searchRef.current.value = "";
    fetchReturns();
    }
      } catch (er) {
    }
    }, []);
    const updateOrderStatus = () => {};
    const downloadInvoice = () => {};

    const updateCreteReturn = () => {
      setCreateRetrun(false)
    }

    const handlePagination = (pgInfo) => {
      setState((prevState) => ({
        ...prevState,
        recPerPage: parseInt(pgInfo?.recPerPage),
        page: pgInfo?.page
      }));
    }
    return (
      <React.Fragment>
        <CHeader>
        <form
      id="filter-form"
       className="d-flex align-items-center searchForm"
      onFinish={console.log}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          setState((prevState) => ({
            ...prevState,
            page: 0,
          }));
          e.preventDefault();
          setKeywordError()
          setOperatorError()
          setFilterTypeError()
          handleUrls()
        }
      }}>
        <div className="d-flex">
        <DatePicker
        className="form-control rangePickerWidth"
        id="dateRange"
        maxDate={new Date()}
        // minDate={new Date("2018-01-01")}
        dateFormat="dd-MM-yyyy"
          selectsRange={true}
          onCalendarClose={(e) => {
            // test(e);

            // document.getElementById("dateRange").focus();
          }}
          startDate={startDate}
          endDate={endDate}
          autoComplete="nope"
          onChange={(update) => {
            changeDateRange(update)
            // let test = document.getElementById("dateRange");
            // test.focus();
          }}
          placeholderText = "Select Date"
          showPreviousMonths
          monthsShown={1}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          form="filter-form"
          // isClearable={true}
        />

          <select id="filter" className="form-control ms-2"
           onChange={(e) => changeFilter(e)}
           style={{width:"50%"}}
           >
            <option value="">Filters </option>
            {filterOptions?.map((filter_opt) => {
              return (<option value={JSON.stringify(filter_opt)} selected={true}>{filter_opt.value}</option>)
            })}
          </select>

          <select className="form-control ms-2" id="subfilter"
            style={{width:"50%"}}
            onChange={(e) => {
              let value;
              if(e.target.value){
                value = e.target.value;
                value = JSON.parse(value)?.value
              }
              setState((prevState) => ({
                ...prevState,
                showClearFilter: true,
                subFilterType:value
              }));
            }}
          >
            <option value="">Sub Filters</option>
            {subFilterOptions?.map((subfilter)=>{
              return (<option value={JSON.stringify(subfilter)}>{subfilter.label}</option>)
            })}
          </select>

          <input
            className="form-control ms-2"
            id="subfilterTxt"
            style={{display:"none"}}
            pattern="/[0-9]"
            minValue={0}
            maxValue={99}
            onBlur={(e) => {
              if(e.target.value < 0){
                document.getElementById('subfilterTxt').value = 0
              }
              if(e.target.value > 99){
                document.getElementById('subfilterTxt').value = 99
              }
            }}
            onChange={(e) => subfilterTxtHandle(e)}
           />


          <input
            className="form-control ms-2"
            placeholder="Name, Email, Order ID, Mobile"
            id="serchKey"
            defaultValue={state?.searchKeyword}
            onChange={(evt) => {
              const { value } = evt.currentTarget;
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                setState((prevState) => ({
                  ...prevState,
                  searchKeyword: (value || "").trim(),
                  showClearFilter: true
                }));
              });
              // setShowClearFilter(true)
              // document.getElementById("clearFilter").style.display="flex";
            }}
          />
          <CButton
          ref={filterSubmitBtnRef}
          id="filterSubmitBtnRef"
            color="primary"
            className="ms-2"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                page: 0,
              }));
              setKeywordError()
              setOperatorError()
              setFilterTypeError()
              handleUrls()
            }}
          >
            Go
            {/* <FontAwesomeIcon icon={faSearch} style={{ cursor: "pointer" }} /> */}
          </CButton>

        </div>
        <div className="d-flex align-items-center w-20">
          <Checkbox
            label="Search Globally"
            value={state.shouldSearchGlobally}
            checked={state.shouldSearchGlobally}
            id="searchGolbally"
            className="ms-4 searchGloballyText"
            onChange={(evt) => {
              setState((prevState) => ({
                ...prevState,
                shouldSearchGlobally: !prevState.shouldSearchGlobally,
                showClearFilter: true
              }));
            }}
          />

          {permissions?.childItems?.length < 10 && <>
            &nbsp;
              <Popup
                trigger={<FontAwesomeIcon icon={faInfoCircle} style={{ cursor: "pointer", marginBottom:"0.4rem", fontSize:"16px" }} />}
                content={<>
                  <div>Global search will work only on following order status{permissions?.childItems?.length > 1 ? (<>es</>) : (<></>)}:</div>
                  <div>
                      {permissions?.childItems?.map((child, i) => {
                        if(child?.isView === 1){
                          return (<>
                            <span><i>{i > 0 ? ', ':''}{child?.moduleName}</i></span>
                          </>)
                        }
                      })}
                  </div>
                </>}
                position='bottom center'
              />
          </>}
        </div>

        <div className="d-flex align-items-center w-20">
          {(state.showClearFilter) &&  <>
            <CButton
            id="clearFilter"
            color="danger"
            className="ms-5 clearFilterBtn"
            onClick={()=>resetFilter()}
          >
            Clear
          </CButton>
          </>}

        </div>
        </form>

        <div className="d-flex align-items-center w-20">
          <button
            // disabled={downloadCSVPermissions()}
            color="blue"
            onClick={() => downloadCSV()}
            title="Export CSV"
            className="btn btn-info"
          >
            {" "}<FontAwesomeIcon icon={faDownload} style={{ cursor: "pointer" }} />
          </button>
          </div>
    </CHeader>
        <CRow>
          <CCol lg={12}>
          {!!state.entries.length && (
              <Pagination handlePagination={handlePagination} state={state} />
            )}
            {state.loading && (<>
            <center>Loading...</center>
          </>)}
            {!state.loading && state.entries.length === 0 ? (
              <div className="d-flex flex-column align-items-center">
                <img alt="No Data" src={NoDataIcon} style={{ height: 400 }} />
                <h4>No Return Order(s) found</h4>
              </div>
            ) : (
              state.entries?.map((entry, index) => (
                <ReturnList key={index} entry={entry} section={state.section} status={state.status} updateStatus={updateStatus} fetchReturns={fetchReturns} />
              ))
            )}

            {!!state.entries.length && (
              <Pagination handlePagination={handlePagination} state={state} />
            )}
          </CCol>
        </CRow>

        <CreateReturn createRetrun={createRetrun} updateCreteReturn={updateCreteReturn}  />
      </React.Fragment>
    );
  }

  export default React.memo(Returns);
