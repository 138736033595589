import React, { useState, useEffect, useRef, useContext, useCallback } from "react";
import { AppContext } from "../../contexts/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { Checkbox } from "semantic-ui-react";
import moment from "moment";
import Swal from "sweetalert2";
import {
  CCol,
  CRow,
  CPagination,
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CCard,
  CCardBody,
  CHeader,
} from "@coreui/react";
import { Button, Table, Form } from "semantic-ui-react";
import { deleteAPI, postAPI } from "../../apiServices";
import { useNavigate, useLocation } from "react-router-dom";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../common-components/Pagination";

let timeout = null;

const localPermits = JSON.parse(localStorage.getItem("module"));
function Coupons() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const search = useLocation().search;
  let qglobal = 0;
  let qpage = new URLSearchParams(search).get("pg");
  let qkeyword = new URLSearchParams(search).get("q");
  let qOperator = new URLSearchParams(search).get("o");
  let qFilterType = new URLSearchParams(search).get("f");
  qglobal = new URLSearchParams(search).get("global");
  let qStartDate = new URLSearchParams(search).get("startDate");
  let qEndDate = new URLSearchParams(search).get("endDate");
  let qDiscountType = new URLSearchParams(search).get("dsicount-type");
  if(qpage != null && qpage > 0){
    qpage = qpage-1;
  }else{
    qpage = 0;
  }

const defaultState = {
  entries: [],
  totalEntries: 0,
    page: qpage,
    recPerPage: 50,
  loading: true,
  slugType: "category",
  confirmDialog: null,
  users: null,
    searchKeyword: qkeyword,
    operator:qOperator,
    filterType: qFilterType,
    startDate: qStartDate,
    endDate: qEndDate,
    discountType: qDiscountType
};

  const url = window.location.hash;
    if((url.match(/\//g) || []).length === 1){
      let listItems = document.querySelectorAll('li');
      listItems.forEach(item => item.classList.remove('c-show'));
    }

  const { setSecondaryHeader } = useContext(AppContext);
  const [state, setState] = useState(defaultState);
  const [couponList, setCouponList] = useState([]);
  const [showConfirm, setshowConfirm] = useState(false);
  const [couponId, setcouponId] = useState(null);
  const [couponName, setcouponName] = useState(null);
  // const [paginationInfo, setPaginationInfo] = useState();
  const [permissions, setPermissions] = useState(
    localPermits.find((o) => o.moduleName == "Coupons")
  );

  const [keywordError, setKeywordError] = useState()
  const [operatorError, setOperatorError] = useState()
  const [filterTypeError, setFilterTypeError] = useState()
  const searchRef = useRef(null);

  if(!state?.searchKeyword){
    let el = document.getElementById('searchKeywordId');
    if(el){
      el.value = "";
    }
  }

  const handleUrls = async () => {
    let data = [];
    if(state.searchKeyword && state.filterType && state.operator){
      data.push({key:'q', val:state.searchKeyword},{key:'f', val:state?.filterType},{key:'o', val:state.operator});
    }
    if(state?.page){
      data.push({key:'pg', val:state?.page+1})
    }
    if(state?.startDate){
      data.push({key:'startDate', val:state.startDate})
    }
    if(state?.endDate){
      data.push({key:'endDate', val:state.endDate})
    }
    if(state?.discountType){
      data.push({key:'dsicount-type', val:state.discountType})
    }
    let searchUrl = '';
    data?.map((ele) => {
      searchUrl = searchUrl + ele?.key+'='+ele?.val+'&'
    })
    let lastChar = searchUrl.charAt(searchUrl.length - 1)
    if(lastChar === '&'){
      searchUrl = searchUrl.slice(0, -1)
    }
    navigate({search : searchUrl})
  }

  const getUserCouponList = async (page = qpage,
    recPerPage = 50,
    searchKeyword = "",
    operator = "equals",
    filterType,
    startDate,
    endDate,
    discountType,
    listingType = "all",) => {

    const data = {
      page,
      recPerPage,
      searchKeyword,
      operator,
      filterType,
      startDate,
      endDate,
      discountType,
      listingType,
    };


    if(filterType){
      data.filterType = filterType
    }
    const { status, payload, message } = await postAPI("/coupons/list", data);

    if(status === 1){
      setCouponList(payload?.data);
      // setPaginationInfo(paginationInfo)

      setState((prevState) => ({
        ...prevState,
        entries: payload?.data || defaultState.entries,
        totalEntries: payload?.totRecords || defaultState.totalEntries,
      }));
    }else{
      setCouponList([])
      if (message !== "No Data Found!!")
        toast.error(message);
      setState((prevState) => ({
        ...prevState,
        entries: defaultState.entries,
        totalEntries: defaultState.totalEntries,
      }));
    }
  };




  useEffect(() => {
    if(!search){
      setState((prevState) => ({
        ...prevState, filterType: null, operator: null, searchKeyword: '', page:0
      }))
      // handleUrls();
      // if(!state.loading){
        getUserCouponList(
          state.page,
          state.recPerPage,
          state.searchKeyword,
          state.operator,
          state.filterType,
          state.startDate,
          state.endDate,
          state.shouldSearchGlobally,
        );
      // }
    }
  }, [search, state.recPerPage])

  useEffect(() => {
    handleUrls();
    getUserCouponList(
      state.page,
      state.recPerPage,
      state.searchKeyword,
      state.operator,
      state.filterType,
      state.startDate,
      state.endDate,
      state.shouldSearchGlobally
    );
  }, [state.page, search]);



  // useEffect(() => {
  //   getUserCouponList();
  // }, []);

  const addNewCoupon = () => {
    navigate("/add-coupon");
    window.localStorage.removeItem("couponId");
  };
  const editCoupon = (coupon) => {
    navigate({
      pathname: "/add-coupon",
      state: {
        coupon: coupon,
      },
    });
  };
  const handleDeleteConfirm = async () => {
    const { status, message } = await deleteAPI(`/coupons/delete/${couponId}`);
    if (status) {
      toast.info(message);
      getUserCouponList();
    }
    setshowConfirm(false);
  };

  const handleData = async (e) => {
    setKeywordError()
    setOperatorError()
    setFilterTypeError()
    let name = e.target.name;
    let value = e.target.value;
    if(name==='filterType'){
      await setState((prevState) => ({
        ...prevState, 'filterType':value
      }));
      if(value){
        document.getElementById("filterType").style.borderColor = ""
      }}else if(name === 'operator'){
      await setState((prevState) => ({
        ...prevState, 'operator':value
      }));
      if(value){
        document.getElementById("operator").style.borderColor = ""
      }
    }
    else if(name === 'discountType'){
      await setState((prevState) => ({
        ...prevState, 'discountType':value
      }));
      if(value){
        document.getElementById("discountType").style.borderColor = ""
      }
    }

    else if(name==='filterTypeCode'){
      await setState((prevState) => ({
        ...prevState, 'searchKeyword':value
      }));
      if(value){
        document.getElementById("filterTypeCode").style.borderColor = ""
      }
    }
  }

  const handlePagination = (pgInfo) => {
    setState((prevState) => ({
      ...prevState,
      recPerPage: parseInt(pgInfo?.recPerPage),
      page: pgInfo?.page
    }));
  }

  useEffect(() => {
    setSecondaryHeader([renderSecondaryHeader()]);
    return () => setSecondaryHeader([]);
  }, [
    state.searchKeyword,
    state.recPerPage,
    state.operator,
    state.filterType,
    state.startDate,
    state.endDate,
    state.discountType,
  ]);

  // if (state.loading) {
  //   Swal.fire({
  //     title: "Loading...",
  //     timerProgressBar: false,
  //     allowOutsideClick: false,
  //     showCancelButton:false,
  //     showConfirmButton:false

  //   })
  // }

  const resetFilter = async () => {
    setState(defaultState);
    await setState((prevState) => ({
      ...prevState,
      searchKeyword: "",
      operator:"",
      filterType: "",
      startDate: "",
      endDate: "",
      discountType: ""
    }));
    document.getElementById('startDate').value = "";
    document.getElementById('endDate').value = "";

    navigate('/coupon')
  }

  const renderSecondaryHeader = () => {
    return (<>

    </>
    );
  };


  return (
    <React.Fragment>
      <CHeader>
        <div className="d-flex">
      <form
       className="d-flex align-items-center w-100 searchForm"
      onKeyPress={(e) => {
        if(e.key === "Enter"){
          setState((prevState) => ({
            ...prevState,
            page: 0,
          }));
          if(state?.filterType !== 'code-type'){
            document.getElementById("searchKeywordId").style.borderColor = ""
            document.getElementById("operator").style.borderColor = ""
          }

          document.getElementById("filterType").style.borderColor = ""

          setKeywordError()
          setOperatorError()
          setFilterTypeError()
          if(state.searchKeyword && state.operator && state.filterType){
            handleUrls()
            getUserCouponList(
              state.page,
              state.recPerPage,
              state.searchKeyword,
              state.operator,
              state.filterType,
              state.startDate,
              state.endDate
            );
          }else{
            if(!state.startDate && !state.endDate){
              if(!state.searchKeyword){
                if(state?.filterType !== 'code-type'){
                  document.getElementById("searchKeywordId").style.borderColor = "red"
                  setKeywordError("Required")
                }
              }
              if(!state.operator){
                document.getElementById("operator").style.borderColor = "red"
                setOperatorError("Required")
              }
              if(!state.filterType){
                document.getElementById("filterType").style.borderColor = "red"
                setFilterTypeError("Required")
              }
            }else{
              handleUrls()
              getUserCouponList(
                state.page,
                state.recPerPage,
                state.searchKeyword,
                state.operator,
                state.filterType,
                state.startDate,
                state.endDate
              );
            }

          }
        }

      }}>
        <div className="d-flex align-items-center w-100">
          <input
            type="date"
            id="startDate"
            className="form-control ms-2"
            placeholder="Valid From"
            onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
            defaultValue={state.startDate ? state.startDate : ''}
            onChange={(evt) => {
              const { value } = evt.currentTarget;
              clearTimeout(timeout);
                setState((prevState) => ({
                  ...prevState,
                  startDate: (value || "").trim(),
                  showClearFilter: true
                }));
                // document.getElementById("clearFilter").style.display="flex";
            }}
          />
          <input
            type="date"
            className="form-control ms-2"
            placeholder="Valid To"
            id="endDate"
            defaultValue={state.endDate}
            onFocus={(e) => (e.target.type = "date")}
            // onBlur={(e) => (e.target.type = "text")}
            onChange={(evt) => {
              const { value } = evt.currentTarget;
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                setState((prevState) => ({
                  ...prevState,
                  endDate: (value || "").trim(),
                  showClearFilter: true,
                }));
              });
              // document.getElementById("clearFilter").style.display="flex";
              if(state.startDate < state.endDate){
              }
            }}
          />
        <select className="ms-2 form-control" name="filterType"
          onChange={(e) => handleData(e)}
          id="filterType"
          style={{borderColor: filterTypeError ? "red" : ""}}
          >
            <option value="">Select</option>
            <option value="coupon-code" selected={state.filterType==='coupon-code' ? true :false}>Coupon Code</option>
            <option value="code-type" selected={state.filterType==='code-type' ? true :false}>Code Type</option>
            {/* <option value="min-order" selected={state.filterType==='min-order' ? true :false}>Min Order</option>
            <option value="discount" selected={state.filterType==='discount' ? true :false}>Discount</option> */}
          </select>
          {state.filterType === 'code-type' ? (<>
            <select className="ms-2 form-control" name="filterTypeCode"
            onChange={(e) => handleData(e)}
            id="filterTypeCode"
            style={{borderColor: filterTypeError ? "red" : ""}}
            >
              <option value="">Select</option>
              <option value="free-shipping" selected={state.searchKeyword==='free-shipping' ? true :false}>Free Shipping</option>
              <option value="Fixed" selected={state.searchKeyword==='Fixed' ? true :false}>Fixed</option>
              <option value="Percent" selected={state.searchKeyword==='Percent' ? true :false}>Percent</option>
            </select>
          </>) : (<></>)}

          <select className="ms-2 form-control"
          onChange={(e) => handleData(e)}
          id="operator" name="operator" style={{borderColor: operatorError ? "red" : ""}}>
            <option value="">Select</option>
            <option value={"equals"} selected={state.operator==='equals' ? true :false}>Equals</option>
            <option value={"contains"} selected={state.operator==='contains' ? true :false}>Contains</option>

          </select>

          {state.filterType === 'discount' ? (<>
            <select className="ms-2 form-control" name="discountType"
          onChange={(e) => handleData(e)}
          id="discountType"
          style={{borderColor: filterTypeError ? "red" : ""}}
          >
            <option value="fixed">Fixed</option>
            <option value="percentage">Percentage</option>
          </select>
          </>) : (<></>)}

          {state.filterType !== 'code-type' ? (<>
          <input
            type="text"
            id="searchKeywordId"
            className="ms-2 form-control"
            placeholder="Coupon Code, Type, ..."
            defaultValue={state?.searchKeyword}
            style={{borderColor: keywordError ? "red" : ""}}
            onChange={(evt) => {
              const { value } = evt.currentTarget;
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                setState((prevState) => ({
                  ...prevState,
                  searchKeyword: (value || "").trim(),
                }));
              });
            }}
          />
          </>) : (<></>)}

          <CButton
            color="primary"
            className="ms-2"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                page: 0,
              }));
              if(state?.filterType !== 'code-type'){
              document.getElementById("searchKeywordId").style.borderColor = ""
                document.getElementById("operator").style.borderColor = ""
              }

              document.getElementById("filterType").style.borderColor = ""

              setKeywordError()
              setOperatorError()
              setFilterTypeError()
              if(state.searchKeyword && state.operator && state.filterType){
                handleUrls()
                getUserCouponList(
                  state.page,
                  state.recPerPage,
                  state.searchKeyword,
                  state.operator,
                  state.filterType,
                  state.startDate,
                  state.endDate
                );
              }else{
                if(!state.startDate && !state.endDate){
                  if(!state.searchKeyword){
                    if(state?.filterType !== 'code-type'){
                    document.getElementById("searchKeywordId").style.borderColor = "red"
                    setKeywordError("Required")
                    }
                  }
                  if(!state.operator){
                    document.getElementById("operator").style.borderColor = "red"
                    setOperatorError("Required")
                  }
                  if(!state.filterType){
                    document.getElementById("filterType").style.borderColor = "red"
                    setFilterTypeError("Required")
                  }
                }else{
                  handleUrls()
                  getUserCouponList(
                    state.page,
                    state.recPerPage,
                    state.searchKeyword,
                    state.operator,
                    state.filterType,
                    state.startDate,
                    state.endDate
                  );
                }

              }
            }}
          >
            <FontAwesomeIcon icon={faSearch} style={{ cursor: "pointer" }} />
          </CButton>
          {((state.searchKeyword && state.operator && state.filterType) || (state.startDate) || (state.endDate)) &&
            <>
            <CButton
              color="danger"
              className="ms-2"
              onClick={()=>resetFilter()}
              // onClick={() => filterData()}
            >
              Clear
            </CButton>
            </>
          }
        </div>
        </form>

        </div>
        <button
          disabled={permissions.isEdit === 0 ? true : false}
          // style={{height:"36px", marginTop:"5px"}}
          className="ms-5 item-align-right btn btn-info"
          onClick={() => addNewCoupon()}
        >
          Add Coupon
        </button>
      </CHeader>
      <CRow>
        <CCol lg={12}>
        {couponList?.length > 0 && (
            <Pagination handlePagination={handlePagination} state={state} />
          )}
          {couponList?.length === 0 ? (
            <div className="d-flex flex-column align-items-center">
              <p>No Coupon Found</p>
            </div>
          ) : (
            <React.Fragment>
              <CRow>
                <CCol lg={9}>
                  <div style={{ width: 200, marginBottom: 10 }}>
                    <Form>
                      <Form.Field></Form.Field>
                    </Form>
                  </div>
                </CCol>
                <CCol lg={3}>

                </CCol>
              </CRow>
              <CCard>
                <CCardBody>
                  <Table basic="very">
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Coupon Code
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Code Type
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Valid From
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Valid Upto
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Created
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Min Order
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Discount
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Status
                        </Table.HeaderCell>
                        <Table.HeaderCell style={{ border: 0 }}>
                          Action
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {couponList?.map((coupon, index) => {

                        let upto = coupon.validUpto * 1000; // First epoch date in milliseconds
                        let current = new Date().getTime(); // Second epoch date in milliseconds
                        let timeDifferenceInMilliseconds = upto - current;
                        let millisecondsInADay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
                        let daysDifference = timeDifferenceInMilliseconds / millisecondsInADay;
                        return (
                        <Table.Row key={index}>
                          <Table.Cell>{coupon.couponCode}</Table.Cell>
                          <Table.Cell>
                            {coupon.codeType.charAt(0).toUpperCase() +
                              coupon.codeType.slice(1)}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(new Date(coupon.validFrom * 1000)).format(
                              "DD MMM yyyy"
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {moment(new Date(coupon.validUpto * 1000)).format(
                              "DD MMM yyyy"
                            )}

                          </Table.Cell>
                          <Table.Cell>
                            {moment(new Date(coupon.createdOn * 1000)).format(
                              "DD MMM yyyy"
                            )}
                          </Table.Cell>
                          <Table.Cell>{coupon.minOrderValue}</Table.Cell>
                          {/*

                            <Table.Cell>{coupon.discVal}</Table.Cell>
                          */}
                          <Table.Cell>
                          {coupon?.codeType === "fixed" ? '₹' : ''}
                            {coupon?.codeType === "percent"
                              ? (coupon?.discVal * 100).toFixed(2)
                              : coupon?.codeType === "fixed" ? coupon?.discVal : '-'}

                            {coupon?.codeType === "percent" ? '%' : ''}
                          </Table.Cell>
                          {coupon.status === 1 && daysDifference > 0 ? (
                            <Table.Cell positive>Active</Table.Cell>
                          ) : (
                            <Table.Cell negative>InActive</Table.Cell>
                          )}
                          <Table.Cell>
                            <div>
                              <FontAwesomeIcon
                                icon={faTrashAlt}
                                style={{ cursor: "pointer" }}
                                color="primary"
                                className={
                                  permissions.isTrash === 0
                                    ? "me-4 disabled"
                                    : "me-4 text-danger"
                                }
                                // className="me-4 text-danger"
                                onClick={() => {
                                  setshowConfirm(true);
                                  setcouponId(coupon?.couponId);
                                  setcouponName(coupon?.couponCode);
                                }}
                              />
                              <FontAwesomeIcon
                                icon={faPencilAlt}
                                style={{ cursor: "pointer" }}
                                color="primary"
                                className={
                                  permissions.isEdit === 0
                                    ? "me-4  disabled"
                                    : "me-4 text-primary"
                                }
                                // className="me-2 text-primary"
                                onClick={() => {
                                  editCoupon(coupon);
                                  localStorage.setItem(
                                    "couponId",
                                    JSON.stringify(coupon?.couponId)
                                  );
                                }}
                              />
                            </div>
                          </Table.Cell>
                        </Table.Row>
                      )})}
                    </Table.Body>
                  </Table>
                </CCardBody>
              </CCard>

            </React.Fragment>
          )}
          {couponList?.length > 0 && (
            <Pagination handlePagination={handlePagination} state={state} />
          )}
        </CCol>
      </CRow>
      <CModal
        alignment="center"
        show={!!state.confirmDialog}
        onClosed={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: null,
          }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`${state.confirmDialog?.action}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>{`Are you sure you want to continue?`}</div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setState((prevState) => ({
                ...prevState,
                confirmDialog: null,
              }));
            }}
          >
            Cancel
          </CButton>
          <CButton color="primary">Delete</CButton>
        </CModalFooter>
      </CModal>
      <CModal
        alignment="center"
        show={showConfirm}
        onClosed={() => {
          setState((prevState) => ({
            ...prevState,
            confirmDialog: null,
          }));
        }}
      >
        <CModalHeader>
          <CModalTitle>{`Delete Coupon ${couponName}`}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <div>{`Are you sure you want to continue?`}</div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={() => {
              setshowConfirm(false);
            }}
          >
            Cancel
          </CButton>
          <CButton
            color="primary"
            onClick={() => {
              handleDeleteConfirm();
            }}
          >
            Delete
          </CButton>
        </CModalFooter>
      </CModal>
    </React.Fragment>
  );
}

export default Coupons;
