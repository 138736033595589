import React, { useState, useEffect } from "react";
import { Table } from "semantic-ui-react";
import { getApiCall } from "../../apiServices";

const ModuleManagement = () => {
  const [moduleList, setmoduleList] = useState([]);
  window.scrollTo(0, 0);
  useEffect(() => {
    async function getModuleData() {
      const { status, payload } = await getApiCall("/modules/list");
      if (status) {
        setmoduleList(payload.items);
      }
    }
    getModuleData();
  }, []);

  return (
    <React.Fragment>
      {moduleList?.length > 0 ? (
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Module Name</Table.HeaderCell>
               <Table.HeaderCell>SubModule Name</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {moduleList?.map((module, index) => (
              <React.Fragment>
                {module?.childItems?.length > 0 ? (
                  <React.Fragment>
                    {module?.childItems?.map((subM, ind) => (
                      <Table.Row key={ind}>
                        <Table.Cell>{module.moduleName}</Table.Cell>
                             <Table.Cell>{subM.moduleName}</Table.Cell>
                      </Table.Row>
                    ))}
                  </React.Fragment>
                ) : (
                  <Table.Row key={index}>
                    <Table.Cell>{module.moduleName}</Table.Cell>
                    <Table.Cell>NA</Table.Cell>

                  </Table.Row>
                )}
              </React.Fragment>
            ))}
          </Table.Body>
        </Table>
      ) : (
        "No Data Found"
      )}
    </React.Fragment>
  );
};

export default React.memo(ModuleManagement);
