import React, {
    useState,
    useEffect,
    useCallback,
    useRef,
    useContext,
  } from "react";

import { Button, Table, Dropdown } from "semantic-ui-react";
import Modal from 'react-bootstrap/Modal';
import { postAPI, putAPI, uploadImageAPIb2b } from "../../apiServices";
import { amountRegex } from "../../constants/regexValidation";
import { toast } from "react-toastify";

const defaultState = {
    selectedItem: [],
  };

  const CreditDebitNoteUpload = ({ entry, noteType, noteUploadModal, toggelNoteUploadModal}, props) => {
    const [shwoNoteUploadModal, setShowNoteUploadModal] = useState();
    const [imageFile, setImageFile] = useState();
    const [fileNumber, setFileNumber] = useState("")
    const [amount, setAmount] = useState()
    const [reasonList, setReasonList] = useState([])
    const [reason, setReason] = useState("");
    const [cancelConfirmVisible, setCancelConfirmVisible] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        getReason();

    },[noteUploadModal])

    // get list of reasons based on notetype and then use these list on upload credit or debit note
    const getReason = async () => {
      let slugType = 'credit-note-upload-reason'
      if(noteType === 'debitNote'){
        slugType = 'debit-note-upload-reason';
      }
      let data = {
        page: 0,
        recPerPage: 50,
        slugType: slugType,
        parent: ""
      }
      let res = await postAPI('/get-data-list', data);

      if(res.status === 1){
        setReasonList(res.payload[slugType])
        setShowNoteUploadModal(noteUploadModal)
      }else{
        setShowNoteUploadModal(false);
        toast.error(res.message)
      }
    }

    const handleViewNoteUploadModalClose = () => {
        setShowNoteUploadModal(false);
        setCancelConfirmVisible(false)
        toggelNoteUploadModal()
    }

    const uploadFile = async (e) => {
        let files = e.target.files
        let payloadimg = await uploadImageAPIb2b("/uploads", files,  {bucket:noteType});
        if(payloadimg?.status === 1){
            await setImageFile(payloadimg?.url?.[0]);
        }
    }

    const handleSubmit = () => {
      // convert comma seperated amount into float and store into new variable finalAmount
      // compare finalAmount instead of amount captured by user
      let finalAmount = Number(amount.replace(/,/g, ''));

      if(noteType === 'creditNote'){
        if(finalAmount === 0 || finalAmount === '0' || finalAmount < 0){
          setErrorMessage("Amount can not be 0")
        }else if((finalAmount < entry?.orderNetAmount) || finalAmount === entry?.orderNetAmount){
          if(reason === ""){
            setErrorMessage("Please select reason")
            return false;
          }
          setCancelConfirmVisible(true)
        }else{
          setErrorMessage("Amount should not be greater than order value")
        }
      }

      if(noteType === 'debitNote'){
        if(finalAmount === 0 || finalAmount === '0' || finalAmount < 0){
          setErrorMessage("Amount can not be 0")
        }else if((finalAmount < entry?.returns?.grandTotal) || finalAmount === entry?.returns?.grandTotal){
          setCancelConfirmVisible(true)
        }else{
          setErrorMessage("Amount should not be greater than order value")
        }
      }

    }

    const saveUploadFile = async () => {
      let finalAmount = amount.replace(/,/g, '');
        let type = 'debit-note';
        let payload = {
            debitNoteURL: imageFile,
            debitNoteNo: fileNumber,
            debitNoteAmount: finalAmount,
            reason: reason,
            bucket: 'debitNote'
        }
        let returnId = entry?.returnId;
        if(noteType === 'creditNote'){
            type = 'credit-note';
            payload = {
                creditNoteURL: imageFile ,
                creditNoteNo: fileNumber,
                creditNoteAmount: finalAmount,
                reason: reason,
                bucket: 'creditNote'
            }
        }
        let res = await putAPI('/return/'+returnId+'/update/'+type, payload);
        if(res.status === 1){
            toast.success(res.message)
            setShowNoteUploadModal(noteUploadModal)
            handleViewNoteUploadModalClose()
        }else{
            handleViewNoteUploadModalClose()
        }
    }

    return (<>
    <Modal show={shwoNoteUploadModal} onHide={handleViewNoteUploadModalClose} backdrop="static" keyboard={false} size="md">
        <Modal.Header>
            <Modal.Title>{noteType === 'creditNote' ? 'Credit Note' : 'Debit Note'} Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="container">
                <div className="row">
                    <div className="col-2"></div>
                    <div className="col-12 mb-2">
                        <div>
                          <input type="file" name="uploadFile" className="form-control" onChange={(e) => uploadFile(e)} style={{height:"auto"}} />
                        </div>
                    </div>
                    <div className="col-12 mb-2">
                        <div>
                          <input type="text" name="number" className="form-control" onChange={(e) => setFileNumber(e.target.value)} style={{height:"auto"}}
                          placeholder={noteType === 'creditNote' ? 'Credit Note Number' : 'Debit Note Number'} />
                        </div>
                    </div>

                    <div className="col-12 mb-2">
                        <div>
                          <input type="text" name="text" className="form-control" onChange={(e) => setAmount(e.target.value)} style={{height:"auto"}}
                          placeholder={noteType === 'creditNote' ? 'Credit Note Amount' : 'Debit Note Amount'} />
                        </div>
                    </div>

                    <div className="col-12 mb-2">
                        <div>
                          <select name="reason" className="form-control" onChange={(e) => setReason(e.target.value)}>
                            <option value="">Select Reason for return</option>
                            {reasonList?.map((row) => {
                              return (<option value={row.attb_title}>{row.attb_title}</option>)
                            })}

                          </select>

                        </div>
                    </div>

                </div>

            </div>
        </Modal.Body>
        <Modal.Footer style={{flexWrap: "nowrap"}}>
            {cancelConfirmVisible ? (<>
              <p><strong>Note: </strong>You will not be able to edit the uploaded note number and amount post this step.</p>
              <Button className="btn btn-danger" onClick={handleViewNoteUploadModalClose}>  No </Button>
              <Button className="btn blue btn-primary btn-md"  onClick={saveUploadFile}> Yes </Button>
            </>) : (<>
            <p className="errorMessage">{errorMessage}</p>
              <Button className="btn btn-danger" onClick={handleViewNoteUploadModalClose}>  Cancel </Button>
              <Button className="btn blue btn-primary btn-md" disabled={(imageFile && amount && reason) ? false : true}  onClick={()=>handleSubmit()}> Submit </Button>
            </>)}
        </Modal.Footer>
    </Modal>
    </>)

  }
  export default CreditDebitNoteUpload
