import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CNavItem,
  CNavTitle,
  CNavGroup,
} from "@coreui/react";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBagShopping, faBasketShopping, faBusAlt, faDashboard, faFileLines, faGear, faLayerGroup, faStar, faTag, faUser, faUserGroup, faBars } from '@fortawesome/free-solid-svg-icons';
import BrandLogo from "../assets/icons/image-1.png";

const renderNavItem = (item, currentPath, expandedItems, setExpandedItems) => {
  const isActive = currentPath.startsWith(item.to);

  switch (item._tag) {
    case 'CSidebarNavTitle':
      return <CNavTitle key={item.name} style={{color:"#fff"}}>{item.name}</CNavTitle>;
    case 'CSidebarNavDropdown':
      return (
        <CNavGroup
          toggler={
            <>
            <span style={{color:"#fff"}}>
              <FontAwesomeIcon icon={item.icon} style={{ color:"#fff", marginRight: "20px" }} />
              {item.name}
              </span>
            </>
          }
          visible={expandedItems.includes(item.name) || item._children.some(child => currentPath.startsWith(child.to))}
          key={item.name}
          onClick={() => {
            if (expandedItems.includes(item.name)) {
              setExpandedItems(expandedItems.filter(name => name !== item.name));
            } else {
              setExpandedItems([...expandedItems, item.name]);
            }
          }}
          style={{color:"#fff"}}
        >
          {item._children.map((child) => renderNavItem(child, currentPath, expandedItems, setExpandedItems))}
        </CNavGroup>
      );
    case 'CSidebarNavItem':
      return (
        <CNavItem
          key={item.name}
          href={'/#' + item.to}
          className={isActive ? 'active-menu-item' : ''}
          style={isActive ? { backgroundColor: "#f0f0f0", fontWeight: "bold", color:"#fff" } : {color:"#fff"}}
        >
          <FontAwesomeIcon icon={item.icon} style={{ marginRight: "20px" }} />
          {item.name}
        </CNavItem>
      );
    default:
      return null;
  }
};

const TheSidebar = ({ visible, toggleSidebar }) => {
  const location = useLocation();
  const [stateNavigation, setStateNavigation] = useState([]);
  const [expandedItems, setExpandedItems] = useState([]);

  useEffect(() => {
    let array = [];
    const localNavigation = JSON.parse(localStorage.getItem("module"));
    localNavigation?.forEach((element) => {
      if (element?.childItems?.length === 0) {
        array.push({
          _tag: "CSidebarNavItem",
          name: element?.moduleName,
          to: element?.modulePath,
          icon: element.moduleName === "Sellers" ? faBusAlt :
            element.moduleName === "Buyers" ? faUserGroup :
            element.moduleName === "Products" || element.moduleName === "Orders" ? faBagShopping :
            element.moduleName === "Return & Refund" ? faBasketShopping :
            element.moduleName === "Access Management" ? faUser :
            element.moduleName === "Manage Category" ? faLayerGroup :
            element.moduleName === "Coupons" ? faTag :
            element.moduleName === "Settings" ? faGear :
            element.moduleName === "Reports" ? faFileLines :
            element.moduleName === "Dashboard" ? faDashboard : faStar,
        });
      } else {
        let child = [];
        let reprtTypeCount = 0;
        element?.childItems?.forEach((childElement) => {
          if (childElement.isView) {
            if(element.moduleName !== 'Reports'){
              child.push({
                _tag: "CSidebarNavItem",
                name: childElement.moduleName,
                to: childElement?.modulePath,
                className: "ms-4",
              });
            }else{
              if(reprtTypeCount < 1){
                child.push({
                  _tag: "CSidebarNavItem",
                  name: "All Reports",
                  to: "/reports/all-reports",
                  className: "ml-4",
                });
              }
              reprtTypeCount++;
            }

          }
        });
        array.push({
          _tag: "CSidebarNavDropdown",
          name: element.moduleName,
          icon: element.moduleName === "Sellers" ? faBusAlt :
            element.moduleName === "Buyers" ? faUser :
            element.moduleName === "Products" || element.moduleName === "Orders" ? faBagShopping :
            element.moduleName === "Return & Refund" ? faBagShopping :
            element.moduleName === "Access Management" ? faUser :
            element.moduleName === "Manage Category" ? faLayerGroup :
            element.moduleName === "Coupons" ? faTag :
            element.moduleName === "Settings" ? faGear :
            element.moduleName === "Reports" ? faFileLines :
            element.moduleName === "Dashboard" ? faDashboard : faStar,
          _children: child,
        });
      }
    });
    setStateNavigation(array);
  }, []);

  useEffect(() => {
    // Ensure that parent menus are expanded if a child is active
    const activeParents = [];
    stateNavigation.forEach((item) => {
      if (item._tag === "CSidebarNavDropdown") {
        item._children.forEach((child) => {
          if (location.pathname.startsWith(child.to)) {
            activeParents.push(item.name);
          }
        });
      }
    });
    setExpandedItems(activeParents);
  }, [location.pathname, stateNavigation]);

  return (
    <CSidebar visible={!visible}
    className="sidebar-ss"
    style={{
      position: "fixed",

    }}>
      <CSidebarBrand className="border-bottom" style={{ backgroundColor: "transparent" }}>
        <img
          src={BrandLogo}
          alt={"logo"}
          className="w-100 c-sidebar-brand-full"
          style={{ height: 45, objectFit: "contain", marginTop: "10px" }}
        />
        <span className="side-nav-toggle-btn" onClick={()=>toggleSidebar()} style={{paddingRight:"10px"}}><FontAwesomeIcon icon={faBars}></FontAwesomeIcon></span>
      </CSidebarBrand>
      <CSidebarNav>
        {stateNavigation.map((item) => renderNavItem(item, location.pathname, expandedItems, setExpandedItems))}
      </CSidebarNav>
    </CSidebar>
  );
};

export default React.memo(TheSidebar);
